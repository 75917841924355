import { createAsyncThunk, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import { okaoAxios } from '../../helpers/axios';
import { store, RootState } from '../../redux/store';
import { Eater, AddEaterRequest, AddDiningReviewRequest } from './types'
import { AlertColor } from '@mui/material/Alert';


export interface IEaterSlice {
  status: string,
  eaters: Eater[] | undefined,
  eater: Eater | undefined,
  count: number,
  pageSize: number,
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined,
}

const initialState: IEaterSlice = {
  status: 'idle',
  eaters: undefined,
  eater: undefined,
  pageSize: 25,
  count: 0,
  shouldOpen: false,
  message: undefined,
  severity: undefined,
};

export const fetchEaters = createAsyncThunk('api/fetchEaters', async (page: number) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/eater/${page}?size=${store.getState().eater.pageSize}`)
  return response;
})

export const fetchEater = createAsyncThunk('api/fetchEater', async (id: string) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/eater/${id}/detail`)
  return response;
})

export const saveEater = createAsyncThunk('api/saveEater', async (nmr: AddEaterRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/eater`, nmr));
  return response;
})

export const addDiningReview = createAsyncThunk('api/addDiningReview', async (nmr: AddDiningReviewRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/eater/save-dining-review`, nmr));
  return response;
})

export const blockUser = createAsyncThunk('api/blockUser', async (id: string) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).put(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/block-user-account/${id}`)
  return response;
})

export const eaterSlice = createSlice({
  name: 'eater',
  initialState,
  reducers: {
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchEaters.fulfilled, (state, action) => {
      state.eaters = action.payload.data.result
      state.count = action.payload.data.count
      state.status = 'idle'
    }).addCase(fetchEater.fulfilled, (state, action) => {
      state.eater = action.payload.data
      state.status = 'idle'
    })
    .addCase(blockUser.fulfilled, (state, action) => {
      state.shouldOpen = true;
      state.message = action.payload.data.message;
      state.severity = 'success';
    })
    .addCase(blockUser.rejected, (state, action) => {
      state.shouldOpen = true;
      state.message = 'Error occurred while blocking user'
      state.status = 'error'
      state.severity = 'error'
    })
    .addMatcher(isAnyOf(addDiningReview.fulfilled, saveEater.fulfilled), (state, action) => {
      const eater: Eater = action.payload.data as Eater
      state.eaters = state.eaters?.filter(sr => sr.eaterId !== eater.eaterId);
      state.eaters?.push(eater);
      state.count = state.eaters?.length ?? 0
      state.shouldOpen = true;
      state.message = 'Successfully Completed Eater function'
      state.status = 'idle'
      state.severity = 'success'
    })
    .addMatcher(isAnyOf(addDiningReview.rejected, fetchEater.rejected, fetchEaters.rejected, saveEater.rejected), (state, action) => {
      state.shouldOpen = true;
      state.message = 'Error occurred while performing an eater action'
      state.status = 'error'
      state.severity = 'error'
      console.log(action.error);
    }).addMatcher(isAnyOf(addDiningReview.pending, fetchEater.pending, fetchEaters.pending, saveEater.pending, blockUser.pending), (state, action) => {
      state.status = 'loading'
    })
  }
});

export const selectEaters = (state: RootState): Eater[] => state.eater.eaters ?? []
export const selectEater = (state: RootState): Eater | undefined => state.eater.eater ?? undefined
export const selectEaterByEaterId = (eaterId: string) => (state: RootState): Eater | undefined => state.eater.eaters?.find(eater => eater.eaterId === eaterId) ?? undefined
export const selectRowCount = (state: RootState): number => state.eater.count
export const selectPageSize = (state: RootState): number => state.eater.pageSize
export const selectIsLoading = (state: RootState): boolean => state.eater.status === "loading"
export const selectShouldOpen = (state: RootState): boolean => state.eater.shouldOpen
export const selectMessage = (state: RootState): string | undefined => state.eater.message
export const selectSeverity = (state: RootState): AlertColor | undefined => state.eater.severity

export const { setPageSize, setOpen } = eaterSlice.actions;

export default eaterSlice.reducer
