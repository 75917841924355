import React from 'react'
import { Route, Routes } from 'react-router-dom' //eslint-disable-line
import HomePage from './pages/HomePage/HomePage'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import UserProfile from './pages/UserProfile/UserProfile'
import Dashboard from './pages/Dashboard/Dashboard'
import MissingMenu from './pages/MissingMenu/MissingMenu'
import Restaurant from './pages/Restaurant/Restaurant'
import Reviews from './pages/Reviews/Reviews'
import Menu from './pages/Menu/Menu'
import Location from './pages/Location/Location'
import AddLocation from './pages/Location/AddLocation'
import AddDish from './pages/Menu/AddDish'
import AddMenu from './pages/Menu/AddMenu'
import AddPress from './pages/Reviews/AddPress'
import Review from './pages/Reviews/Review'
import Press from './pages/Reviews/Press'
import Eaters from './pages/Eater/Eaters'
import Eater from './pages/Eater/Eater'
import AddDiningReview from './pages/Eater/AddDiningReview'
import AddEater from './pages/Eater/AddEater'
import DiningReview from './pages/Eater/DiningReview'
import DiningReviews from './pages/Eater/DiningReviews'
import UnauthorizedPage from './pages/UnauthorizedPage/UnauthorizedPage'
import RequestAccess from './pages/Admin/RequestAccess'
import PendingAccess from './pages/Admin/PendingAccess'
import AdminRequests from './pages/Admin/AdminRequests'
import Admins from './pages/Admin/Admins'
import OnboardingRestaurant from './pages/OnbordingRestaurantRequests/OnboardingRestaurant'
import UnblockUsers from './pages/UnblockUsers/UnblockUsers'

const RouterApp: React.FC = () => {
    // eslint-disable-next-line

    return (
        <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route path={ROUTES.UNAUTHORIZED_ROUTE} element={<UnauthorizedPage />} />
            <Route path={ROUTES.REQUEST_ACCESS_ROUTE} element={<RequestAccess/>} />
            <Route path={ROUTES.PENDING_ACCESS_ROUTE} element={<PendingAccess/>} />
            <Route path={ROUTES.ADMIN_REQUESTS_ROUTE} element={<AdminRequests/>} />
            <Route path={ROUTES.ADMINS_ROUTE} element={<Admins/>} />
            <Route path={ROUTES.ONBOARDING_RESTAURANT_REQUEST_ROUTE} element={<OnboardingRestaurant/>} />
            <Route path={ROUTES.UNBLOCK_USERS} element={<UnblockUsers/>} />
            <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
            <Route path={ROUTES.PROFILE_ROUTE} element={<UserProfile />} />
            <Route path={ROUTES.DASHBOARD_ROUTE} element={<Dashboard />} />
            <Route path={ROUTES.MISSING_MENU_ROUTE} element={<MissingMenu />} />
            <Route path={ROUTES.LOCATION_ROUTE} element={<Location />} />
            <Route path={ROUTES.ADD_LOCATION_ROUTE} element={<AddLocation />} />
            <Route path={ROUTES.EATERS_ROUTE} element={<Eaters />} />
            <Route path={`${ROUTES.EATERS_ROUTE}/:eaterid`} element={<Eater />} />
            <Route path={`${ROUTES.EATERS_ROUTE}${ROUTES.ADD_NEW_EATER_ROUTE}`} element={<AddEater />} />
            <Route path={`${ROUTES.EATERS_ROUTE}/:eaterid${ROUTES.DINING_REVIEW_ROUTE}/add-dining-review`} element={<AddDiningReview />} />
            <Route path={`${ROUTES.EATERS_ROUTE}/:eaterid${ROUTES.DINING_REVIEW_ROUTE}/:reviewid`} element={<DiningReview />} />
            <Route path={`${ROUTES.EATERS_ROUTE}/:eaterid${ROUTES.DINING_REVIEW_ROUTE}`} element={<DiningReviews />} />
            <Route path={`${ROUTES.RESTAURANT_ROUTE}/:alias${ROUTES.ADD_NEW_MENU_ROUTE}`} element={<AddMenu />} />
            <Route path={`${ROUTES.RESTAURANT_ROUTE}/:alias`} element={<Restaurant />} />
            <Route path={`${ROUTES.RESTAURANT_ROUTE}/:alias${ROUTES.MENU_ROUTE}/:menuID`} element={<Menu />} />
            <Route path={`${ROUTES.RESTAURANT_ROUTE}/:alias${ROUTES.MENU_ROUTE}/:menuID/add-dish`} element={<AddDish />} />
            <Route path={`${ROUTES.RESTAURANT_ROUTE}/:alias${ROUTES.REVIEW_ROUTE}`} element={<Reviews />} />
            <Route path={`${ROUTES.RESTAURANT_ROUTE}/:alias${ROUTES.REVIEW_ROUTE}/add-press`} element={<AddPress />} />
            <Route path={`${ROUTES.RESTAURANT_ROUTE}/:alias${ROUTES.REVIEW_ROUTE}/:reviewID`} element={<Review />} />
            <Route path={`${ROUTES.RESTAURANT_ROUTE}/:alias${ROUTES.PRESS_ROUTE}/:pressID`} element={<Press />} />
        </Routes>
    )
}

export default RouterApp
