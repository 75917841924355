/* eslint-disable */

import React, {useState} from 'react'
import PageContainer  from '../../components/PageContainer/PageContainer';
import { addPress } from './slice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { AddPressContainer, AddPressTitle, AddPressContentHeader, AddPressContentBody } from './Reviews.styled';
import { AddPressRequest } from './types';
import { selectCurrentRestaurant, setOpen, selectShouldOpen, selectMessage, selectSeverity } from '../Restaurant/slice';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const AddPress: React.FC = () => {
  const dispatch = useAppDispatch();
  const[ entity, setEntity ] = useState('');
  const[ link, setLink ] = useState('');
  const[ parser, setParser ] = useState('');
  const { alias } = useParams();
  const restaurant = useAppSelector(selectCurrentRestaurant);

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: restaurant?.name??'', 
      url:`/restaurant/${alias}`
    },
    {
      name: 'Reviews', 
      url:`/restaurant/${alias}/reviews`
    }
  ]

  const openServer = useAppSelector(selectShouldOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }
    
  return (
    <PageContainer title="Add Press" breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
    <AddPressContainer>
      <AddPressContentHeader>
        <AddPressTitle>Add Press</AddPressTitle>
          <Stack direction="row" spacing={2}>
            <Button variant="text" onClick={() => {
              const addPressRequest: AddPressRequest = {
                restaurantID: restaurant?.restaurantID??'',
                entity: entity??'',
                link: link??'',
                parser: parser??''
              }
              dispatch(addPress(addPressRequest));
              setEntity('');
              setLink('');
              setParser('');
            }}>Save</Button>
          </Stack>
      </AddPressContentHeader>
      <AddPressContentBody>
        <Stack direction="row" spacing={5}>
          <TextField id="entity" label="Press Entity Name" variant="outlined" value={entity} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEntity(event.target.value);
          }}/>
          <TextField id="link" label="Press Link" variant="outlined" value={link} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setLink(event.target.value);
          }}/>
          <TextField id="parser" label="Parser" variant="outlined" value={parser} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setParser(event.target.value);
          }}/>
        </Stack>
        </AddPressContentBody>
      </AddPressContainer>
    </PageContainer>
  )
}

export default withAuthenticationRequired(AddPress)
