/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Container } from './Admin.styled';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchAdminRequests, setPageSize, selectPageSize, selectAdminRequestRowCount, selectShouldOpen, selectAdminRequests, selectMessage, selectSeverity, setOpen, grantAdminAccess, declineAdminAccess } from './slice';
import PageContainer from '../../components/PageContainer/PageContainer';
import { useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  GridColumns, 
  GridRowModel,
  GridEventListener,
  GridRowParams,
  MuiEvent,
  GridCallbackDetails
} from '@mui/x-data-grid';
import { GridRowProps } from 'semantic-ui-react';
import Grid from '../../components/Grid/Grid'
import { AccessRequest, AdminRequest } from './types'
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { EditToolbar } from '../../components/EditToolbar/EditToolbar';

const Eaters: React.FC = () => {
  const openServer = useAppSelector(selectShouldOpen);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const adminRequestsFromServer = useAppSelector(selectAdminRequests);
  const pageSize = useAppSelector(selectPageSize)
  const [adminRequests, setAdminRequests] = useState(adminRequestsFromServer);
  const rowCount = useAppSelector(selectAdminRequestRowCount);

  
  useEffect(()=>{
    setAdminRequests(adminRequestsFromServer)
  },[
    adminRequestsFromServer
  ])


  useEffect(() => {
    dispatch(fetchAdminRequests(0));
  }, [dispatch])

  const rows: GridRowProps[] = adminRequests??[].map((adminRequest: AdminRequest) => {
    return {
            id: adminRequest.adminRequestID,
            adminRequestId: adminRequest.adminRequestID,
            name: adminRequest.name,
            type: adminRequest.type,
            email: adminRequest.email,
            status: adminRequest.status,
            created: adminRequest.created,
            updated: adminRequest.updated
          }
  })
  
  const columns: GridColumns = [
    { field: 'name', headerName: "Name", width: 200},
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'status', headerName: 'Status', width: 150},
    { field: 'type', headerName: 'Type', width: 150, editable: true},
    { field: 'created', headerName: 'Created Date', width: 300 },
  ];

  const id = randomId()
  const empty = {focusField: 'name', fields: { 
    id,
    adminRequestId: id,
    name: '',
    type: '',
    email: '',
    status: '',
    created: '',
    updated: ''
  }
  }

  const getRowID = (row: AdminRequest): string => {
    return row.adminRequestID;
  }
    
  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  return (
      <PageContainer title='Admin Access Requests' open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
        <Container>
          <Grid 
            rows={rows} 
            setRows={setAdminRequests}
            totalRowCount={rowCount}
            dataColumns={columns}
            paginationServer={true}
            EditToolbar={EditToolbar}
            empty={empty}
            getRowId={getRowID}
            nextPageFn={(newPage: number) => dispatch(fetchAdminRequests(newPage))}
            rowClickedFn={(id: string) => {/* do nothing */}}
            deleteFn={(id: string) => {
              const row = adminRequests?.find(req => req.adminRequestID === id)??undefined;
              if(row !== undefined){
                const req: AccessRequest = {
                  email: row.email
                }
                dispatch(declineAdminAccess(req));
              }
            }}
            editable={true}
            pageSize={pageSize}
            saveFn={(newRow) => {
              const req: AccessRequest = {
                name: newRow.name,
                email: newRow.email,
                type: newRow.type
              }
              dispatch(grantAdminAccess(req));
            }}
            pageSizeOptions={[25,50,100]}
            setPageSize={(pageSize: number) => {dispatch(setPageSize(pageSize))}}
            dashboard={false}
            shouldNavigate={true}
            />
        </Container>
      </PageContainer>
  )
}

export default withAuthenticationRequired(Eaters)
