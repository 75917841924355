import React from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Page,
    LeftNavigation,
    TopNavigation,
    Logo,
    Logout,
    NavContainer,
    PageLabel,
    Profile,
    Notification,
    User,
    Breadcrumb,
    Children,
    Bottom,
    MenuItems,
    MissingMenu,
    Locations,
    Eaters,
    HomeIconContainer,
    Access,
    Admin,
    RestaurantRequests,
    HighLightButton,
    HighLightContainer,
    Element, UnblockUsers
} from './PageContainer.styled';

import { useAuth0 } from '@auth0/auth0-react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import { selectUserInfo, selectLogin } from '../SecureRoute/slice';
import { useAppSelector } from '../../redux/hooks';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface BreadCrumbLink {
    name: string,
    url: string
}

// eslint-disable-next-line
type Props = {
    title?: string
    breadcrumbs?: BreadCrumbLink[]
    message?: string
    children?: any
    open: boolean
    setOpen: (r: boolean) => void,
    severity?: AlertColor
  };

const PageContainer: React.FC<Props> = ({
    title,
    breadcrumbs,
    children,
    message,
    open,
    setOpen,
    severity
}: Props): JSX.Element => {
    const navigate = useNavigate()
    const user = useAppSelector(selectUserInfo);
    const login = useAppSelector(selectLogin)
    const { logout } = useAuth0();

    const isSuperAdmin = login?.adminDTO?.type === 'SUPERADMIN';
    const isEaterAdmin = login?.adminDTO?.type === 'EATER' || login?.adminDTO?.type === 'SUPERADMIN';
    const isRestaurantAdmin = login?.adminDTO?.type === 'RESTAURANT' || login?.adminDTO?.type === 'EATER' || login?.adminDTO?.type === 'SUPERADMIN';

    // eslint-disable-next-line
    const logoutUser = async() => {
        logout({logoutParams: {returnTo: window.location.origin}});
    }


    // eslint-disable-next-line
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setOpen(false);
    };

  return (
    <Page>
        <LeftNavigation>
            { isRestaurantAdmin && <Logo onClick={() => {
                navigate('/')
            }}/> }
            <MenuItems>
                { isRestaurantAdmin && 
                    <HighLightContainer>
                        {title === "Missing Menus" ? <Element><MissingMenu onClick={() => { navigate('/missing-menu')}}/></Element> : 
                        <MissingMenu onClick={() => { navigate('/missing-menu')}}/>}
                    </HighLightContainer>
                }
                { isSuperAdmin && 
                    <HighLightContainer>
                        {title === "Locations" ? <HighLightButton><Locations onClick={() => {navigate('/locations')}}/></HighLightButton> : 
                        <Locations onClick={() => {navigate('/locations')}}/>}
                    </HighLightContainer>
                }
                { isEaterAdmin && 
                    <HighLightContainer>
                    {title === "Eaters" ? <Element><Eaters onClick={() => {navigate('/eaters')}}/></Element> : 
                          <Eaters onClick={() => {navigate('/eaters')}}/>}
                     </HighLightContainer>
                }
                { isSuperAdmin && 
                    <HighLightContainer>
                    {title === "Onboarding Requests" ? <HighLightButton><RestaurantRequests onClick={() => {navigate('/onboarding-restaurant-requests')}}/></HighLightButton> : 
                          <RestaurantRequests onClick={() => {navigate('/onboarding-restaurant-requests')}}/>}
                    </HighLightContainer>
                }
                { isSuperAdmin && 
                    <HighLightContainer>
                    {title === "Unblock Users" ? <HighLightButton><UnblockUsers onClick={() => {navigate('/unblock-users')}}/></HighLightButton> :
                          <UnblockUsers onClick={() => {navigate('/unblock-users')}}/>}
                    </HighLightContainer>
                }
                { isSuperAdmin && 
                    <HighLightContainer>
                    {title === "Admin Access Requests" ? <HighLightButton><Access onClick={() => navigate('/access-control')}/></HighLightButton> : 
                            <Access onClick={() => navigate('/access-control')}/>}
                    </HighLightContainer>
                }
                { isSuperAdmin && 
                    <HighLightContainer>
                    {title === "Admins" ? <HighLightButton><Admin onClick={() => navigate('/admins')}/></HighLightButton> : 
                            <Admin onClick={() => navigate('/admins')}/>}
                    </HighLightContainer>
                }
            </MenuItems>
            <Bottom>
                {/* eslint-disable-next-line */}
                <Logout onClick={logoutUser}/>
            </Bottom>
        </LeftNavigation>
        <TopNavigation>
            <NavContainer>
                <PageLabel>{title}</PageLabel>
                <Profile>
                    Hi {user?.nickname}!
                    <Notification count={0}/>
                    <User profile={user?.picture} onClick={() => {
                        navigate('/profile');
                    }}/>
                </Profile>
            </NavContainer>
            <Breadcrumb>
                <Breadcrumbs aria-label="breadcrumb">
                    <HomeIconContainer onClick={() => {navigate('/')}}>
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    </HomeIconContainer>
                    {(title !== 'Dashboard') && 
                        breadcrumbs?.map(b => {
                            return (<div
                                key={b.name}
                            onClick={() => {navigate(b.url)}}
                            >
                            {b.name}
                            </div>)
                    })}
                    {(title !== 'Dashboard') && 
                            <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                            >
                            {title}
                            </Typography>
                    }
                </Breadcrumbs>
            </Breadcrumb>
        </TopNavigation>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity??'success'} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
        <Children>
            {children}
        </Children>
    </Page>
  );
};

export default PageContainer;
