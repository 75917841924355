/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Container, LoginContainer, LogoContainer, Logo} from './HomePage.styled';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUserInfo, setAccessToken, loginAudit, selectLogin } from '../../components/SecureRoute/slice';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const loginResponse = useAppSelector(selectLogin);
  const [open, setOpen] = useState(false);

  const login = async() => {
    loginWithRedirect();
  }

  useEffect(() => {
    if(!isLoading){
      // eslint-disable-next-line
      if (isAuthenticated) {
        const status = loginResponse?.status;
        if(status === 'ACTIVE'){
            setOpen(false);
            navigate('/dashboard');
        }else if(status === 'NEW'){
            setOpen(false);
            navigate('/request-access')
        }else if(status === 'PENDING'){
          setOpen(false);
          navigate('/pending-access')
      }else{
          setOpen(false);
          navigate('/unauthorized');
        }
      }
    }
  }, [loginResponse])

  useEffect(() => {
    if(!isLoading){
      // eslint-disable-next-line
      if (isAuthenticated) {
        setOpen(true);
        // eslint-disable-next-line
        getAccessTokenSilently().then(token => {
          dispatch(setAccessToken(token));
          dispatch(getUserInfo())
          dispatch(loginAudit())
        })
      }
    }
  }, [isLoading, isAuthenticated]); // Update if authState changes

  return (
    <Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <LogoContainer>
        <Logo/>
      </LogoContainer>
      <LoginContainer>
        <Button onClick={login} dtype='primary'>Login</Button>
      </LoginContainer>
    </Container>
  )
}

export default HomePage
