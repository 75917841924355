import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { okaoAxios } from '../../helpers/axios';
import { RootState, store } from '../../redux/store';
import { Summary } from '../Restaurant/types';
import { NeedsMenu } from './types'


export interface IMissingMenuSlice {
  needsMenus?: NeedsMenu[] | undefined;
  status: string
  count: number
  pageSize: number
  countByStatus: Summary[] | undefined
}

const initialState: IMissingMenuSlice = {
  needsMenus: undefined,
  pageSize: 100,
  status: 'idle',
  count: 0,
  countByStatus: undefined
};

export const fetchNeedsMenus = createAsyncThunk('api/fetchNeedsMenus', async (page: number) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/menu/missing-menu/${page}?size=${store.getState().missingMenu.pageSize}`)
  return response;
})

export const deleteMissingMenu = createAsyncThunk('api/deleteMissingMenu', async (id: string) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).delete(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/menu/missing-menu/${id}`));
  return response;
})

export const missingMenuSlice = createSlice({
  name: 'missingMenu',
  initialState,
  reducers: {
    setNeedsMenus: (state, action: PayloadAction<NeedsMenu[] | undefined>) => {
        state.needsMenus = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    }  
  },
  extraReducers: builder => {
    builder.addCase(fetchNeedsMenus.pending, (state, action) => {
      state.status = 'loading'
    })
    .addCase(fetchNeedsMenus.fulfilled, (state, action) => {
      state.needsMenus = action.payload.data.result
      state.count = action.payload.data.count
      state.status = 'idle'
      state.countByStatus = action.payload.data.countByStatus
    })
    .addCase(fetchNeedsMenus.rejected, (state, action) => {
      state.status = 'error'
    })
  }
});

export const selectNeedsMenus = (state: RootState): NeedsMenu[] => state.missingMenu.needsMenus ?? []
export const selectRowCount = (state: RootState): number => state.missingMenu.count
export const selectPageSize = (state: RootState): number => state.missingMenu.pageSize
export const selectIsLoading = (state: RootState): boolean => state.missingMenu.status === "loading"
export const selectCountByStatus = (state: RootState): Summary[] | undefined => state.missingMenu.countByStatus

export const { setNeedsMenus, setPageSize } = missingMenuSlice.actions;
export default missingMenuSlice.reducer
