import { PayloadAction, createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit';
import { okaoAxios } from '../../helpers/axios';
import { RootState, store } from '../../redux/store';
import { Onboarding } from './types';
import { AlertColor } from '@mui/material/Alert';

export interface IOnboardingSlice {
    status: string,
    restaurants: Onboarding[] | [],
    count: number,
    pageSize: number,
    shouldOpen: boolean
    message: string | undefined
    severity: AlertColor | undefined,
    approveRequestToggle: boolean,
  }

const initialState: IOnboardingSlice = {
    status: 'idle',
    restaurants: [],
    pageSize: 100,
    count: 0,
    shouldOpen: false,
    message: undefined,
    severity: undefined,
    approveRequestToggle: false,
}

export const fetchOnboardingRestaurantRequest = createAsyncThunk('api/onboarding-requests', async (page: number) => {
    const auth = store.getState().secure.accessToken ??'';
    const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/onboarding-requests?size=${store.getState().onboarding.pageSize}&page=${page}`);
    return response;
});

export const approveOnboardingRequest = createAsyncThunk('api/approve-onboarding-request', async (id: string) => {
    const auth = store.getState().secure.accessToken ??'';
    const response = await okaoAxios(auth).put(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/onboarding-requests/${id}`)
    return response;
})

export const declineOnboardingRequest = createAsyncThunk('api/decline-onboarding-request', async (id: string) => {
    const auth = store.getState().secure.accessToken ??'';
    const response = await okaoAxios(auth).put(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/decline-onboarding-request/${id}`)
    return response;
})


export const restaurantOnboardingRequestSlice = createSlice({
    name: 'onboarding', 
    initialState,
    reducers: {
        setPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload
        },
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.shouldOpen = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchOnboardingRestaurantRequest.fulfilled, (state, action) => {
            state.restaurants = action.payload.data.result;
            state.status = 'idle';
            state.count = state.restaurants.length;
        })
        .addMatcher(isAnyOf(approveOnboardingRequest.fulfilled, declineOnboardingRequest.fulfilled), (state, action) => {
            state.status = 'idle';
            state.count = state.restaurants?.length??0;
            state.approveRequestToggle = !state.approveRequestToggle;
            state.shouldOpen = true;
            state.severity = 'success'
            state.message = 'Successfully Completed Onboarding function'
        })
        .addMatcher(isAnyOf(fetchOnboardingRestaurantRequest.pending, approveOnboardingRequest.pending, declineOnboardingRequest.pending), (state, action) => {
            state.status = 'loading';
        })
        .addMatcher(isAnyOf(fetchOnboardingRestaurantRequest.rejected, approveOnboardingRequest.rejected, declineOnboardingRequest.rejected), (state, action) => {
            state.shouldOpen = true;
            state.status = "error";
            state.severity = 'error'
            state.message = 'Error occurred while performing an onboarding action'
        })   
    }
});

export const selectOnboardingRequests = (state: RootState): any => state.onboarding.restaurants?? [];
export const selectRowCount = (state: RootState): number => state.onboarding.count;
export const selectApproveRequestToggle = (state: RootState): boolean => state.onboarding.approveRequestToggle;
export const selectSeverity = (state: RootState): AlertColor | undefined => state.onboarding.severity;
export const selectPageSize = (state: RootState): number => state.onboarding.pageSize;
export const selectMessage = (state: RootState): string | undefined => state.onboarding.message;
export const selectShouldOpen = (state: RootState): boolean => state.onboarding.shouldOpen

export const {setPageSize, setOpen} = restaurantOnboardingRequestSlice.actions;
export default restaurantOnboardingRequestSlice.reducer;