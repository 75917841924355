/* eslint-disable */

import React from 'react'
import PageContainer  from '../../components/PageContainer/PageContainer';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { AddPressContainer, AddPressContentHeader, AddPressContentBody } from './Reviews.styled';
import { selectCurrentRestaurant, setOpen, selectShouldOpen, selectMessage, selectSeverity } from '../Restaurant/slice';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const ReviewDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const { alias, reviewID } = useParams();
  const restaurant = useAppSelector(selectCurrentRestaurant);
  const review = restaurant?.reviews?.find(review => review.reviewId === reviewID)

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: restaurant?.name??'', 
      url:`/restaurant/${alias}`
    },
    {
      name: 'Reviews', 
      url:`/restaurant/${alias}/reviews`
    }
  ]

  const openServer = useAppSelector(selectShouldOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }
    
  return (
    <PageContainer title="Review Detail" breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
    <AddPressContainer>
      <AddPressContentHeader>
        {review?.source}
      </AddPressContentHeader>
      <AddPressContentBody>
        <Stack direction="column" spacing={5}>
          <div>
            {review?.sentimentLabel}
          </div>
          <div>
            {review?.sentimentScore}
          </div>
          <div>
            {review?.sentimentSummary}
          </div>
          <div>
            {review?.sentimentTags}
          </div>
          <div>
            {review?.value}
          </div>
        </Stack>
        </AddPressContentBody>
      </AddPressContainer>
    </PageContainer>
  )
}

export default withAuthenticationRequired(ReviewDetail)
