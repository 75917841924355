import styled from "styled-components";

interface OkaoButtonType {
    dtype: string,
    order?: number
}

export const OkaoButton = styled.button<OkaoButtonType>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    gap: 8px;

    width: ${props => props.dtype === 'primary' ? '5.1875rem' : '6.0625rem'};
    height: 40px;

    /* Primary */

    border: ${props => props.dtype === 'primary' ? '0.0625rem solid #1F2041' : ''};
    border-radius: 0.5rem;
    background: ${props => props.dtype === 'secondary' ? '#FF864C' : '#FFFFFF'};

    /* Inside auto layout */

    flex: none;
    order: ${props => props.order !== 0 ? props.order : 0};
    flex-grow: 0;
`

interface ButtonContent {
    dtype: string
}

export const ContentContainer = styled.div<ButtonContent>`
    width: ${props => props.dtype === 'primary' ? '43px' : '57px'};
    height: 24px;

    /* Text/Regular/Semi Bold */

    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 150%;
    /* identical to box height, or 24px */


    /* Primary */

    color: ${props => props.dtype === 'primary' ? '#1F2041' : '#FFFFFF'};


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`