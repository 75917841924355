import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';

const PendingAccessPage: React.FC = () => {
    const { logout } = useAuth0();
    
    // eslint-disable-next-line
    const logoutUser = async() => {
        logout({logoutParams: {returnTo: window.location.origin}});
    }

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h1 style={{ fontSize: '4em' }}>Your Access Request is Pending</h1>

            {/* eslint-disable-next-line */}
            <p style={{ cursor: 'pointer'}} onClick={logoutUser}>Logout</p>
        </div>
    )
}

export default PendingAccessPage
