import React from 'react'
import {
    ContentContainer,
    OkaoButton
} from './Button.styled'
// eslint-disable-next-line
type Props = {
    dtype?: string
    children?: any
    onClick: any
    order?: number
  };

const Button: React.FC<Props> = ({
    dtype,
    children,
    onClick,
    order
}: Props): JSX.Element => {

  return (
    <OkaoButton dtype={dtype??'primary'} onClick={onClick} order={order}>
      <ContentContainer dtype={dtype??'primary'}>
          {children}
      </ContentContainer>
    </OkaoButton>
  );
};

export default Button;
