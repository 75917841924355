import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { requestAdminAccess, selectAccessResponse, selectIsLoading } from './slice';
import LoadingButton from '@mui/lab/LoadingButton'
import RequestAccessButton from '@mui/icons-material/AddBox';
import TextField from '@mui/material/TextField';
import { AccessRequest } from './types';

const RequestAccessPage: React.FC = () => {
    const { logout } = useAuth0();
    const dispatch = useAppDispatch()
    const isLoading = useAppSelector(selectIsLoading);
    const accessResponse = useAppSelector(selectAccessResponse);
    const [name, setName] = useState('');
    
    // eslint-disable-next-line
    const logoutUser = async() => {
        logout({logoutParams: {returnTo: window.location.origin}});
    }

    const requestAccess = (): void => {
        const req: AccessRequest = {
            name
        }
        // eslint-disable-next-line
        dispatch(requestAdminAccess(req));
    }

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h1 style={{ fontSize: '4em' }}>You need access granted to access this application</h1>

            { accessResponse === undefined && 
                <div>
                    <TextField id="name" sx={{width: '73rem'}} label="name" value={name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }}/>
                    <LoadingButton variant="contained" size="small" loading={isLoading} loadingPosition="start" startIcon={<RequestAccessButton />}onClick={() => requestAccess()}>Request Access</LoadingButton>
                </div>}
            { accessResponse !== undefined && <p>Access has been requested!</p>}
            {/* eslint-disable-next-line */}
            <p style={{ cursor: 'pointer'}} onClick={logoutUser}>Logout</p>
        </div>
    )
}

export default RequestAccessPage
