import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, store } from '../../redux/store';
import { UserInfo, Login } from './types';
import { okaoAxios } from '../../helpers/axios';



export interface ISecureSlice {
  accessToken: string | undefined
  user: UserInfo | undefined
  login: Login | undefined
}

const initialState: ISecureSlice = {
  accessToken: undefined,
  user: undefined,
  login: undefined,
};

export const getUserInfo = createAsyncThunk('api/getUserInfo', async () => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`https://${process.env.REACT_APP_AUTH0_DOMAIN??''}/userinfo`)
  return response;
})

export const loginAudit = createAsyncThunk('api/loginAudit', async () => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/login/admin`)
  return response;
})

export const secureSlice = createSlice({
  name: 'secure',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | undefined>) => {
      state.accessToken = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.user = action.payload.data
    })
    .addCase(loginAudit.fulfilled, (state, action) => {
      state.login = action.payload.data
    })
  }
});

export const selectAccessToken = (state: RootState): string | undefined => state.secure.accessToken
export const selectUserInfo = (state: RootState): UserInfo | undefined => state.secure.user
export const selectLogin = (state: RootState): Login | undefined => state.secure.login

export const { setAccessToken } = secureSlice.actions;
export default secureSlice.reducer
