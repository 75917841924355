/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Container } from './Admin.styled';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchAdmins, setPageSize, selectPageSize, selectShouldOpen, selectMessage, selectSeverity, setOpen, deleteAdmin, updateAdmin, selectAdmins, selectAdminRowCount } from './slice';
import PageContainer from '../../components/PageContainer/PageContainer';
import { useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  GridColumns, 
  GridRowModel,
  GridEventListener,
  GridRowParams,
  MuiEvent,
  GridCallbackDetails
} from '@mui/x-data-grid';
import { GridRowProps } from 'semantic-ui-react';
import Grid from '../../components/Grid/Grid'
import { AccessRequest, Admin } from './types'
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { EditToolbar } from '../../components/EditToolbar/EditToolbar';

const Eaters: React.FC = () => {
  const openServer = useAppSelector(selectShouldOpen);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const adminsFromServer = useAppSelector(selectAdmins);
  const pageSize = useAppSelector(selectPageSize)
  const [admins, setAdmins] = useState(adminsFromServer);
  const rowCount = useAppSelector(selectAdminRowCount);

  
  useEffect(()=>{
    setAdmins(adminsFromServer)
  },[
    adminsFromServer
  ])


  useEffect(() => {
    dispatch(fetchAdmins(0));
  }, [dispatch])

  const rows: GridRowProps[] = admins??[].map((admin: Admin) => {
    return {
            id: admin.adminID,
            adminRequestId: admin.adminID,
            name: admin.name,
            type: admin.type,
            email: admin.email,
            status: admin.status,
            created: admin.created,
            updated: admin.updated
          }
  })
  
  const columns: GridColumns = [
    { field: 'name', headerName: "Name", width: 200},
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'status', headerName: 'Status', width: 150, editable: true},
    { field: 'type', headerName: 'Type', width: 150, editable: true},
    { field: 'created', headerName: 'Created Date', width: 300 },
  ];

  const id = randomId()
  const empty = {focusField: 'name', fields: { 
    id,
    adminRequestId: id,
    name: '',
    type: '',
    email: '',
    status: '',
    created: '',
    updated: ''
  }
  }

  const getRowID = (row: Admin): string => {
    return row.adminID;
  }
    
  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  return (
      <PageContainer title='Admins' open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
        <Container>
          <Grid 
            rows={rows} 
            setRows={setAdmins}
            totalRowCount={rowCount}
            dataColumns={columns}
            paginationServer={true}
            EditToolbar={EditToolbar}
            empty={empty}
            getRowId={getRowID}
            nextPageFn={(newPage: number) => dispatch(fetchAdmins(newPage))}
            rowClickedFn={(id: string) => {/* do nothing */}}
            deleteFn={(id: string) => {
              const row = admins?.find(req => req.adminID === id)??undefined;
              if(row !== undefined){
                const req: AccessRequest = {
                  email: row.email
                }
                dispatch(deleteAdmin(req));
              }
            }}
            editable={true}
            pageSize={pageSize}
            saveFn={(newRow) => {
              const req: AccessRequest = {
                status: newRow.status,
                email: newRow.email,
                type: newRow.type
              }
              dispatch(updateAdmin(req));
            }}
            pageSizeOptions={[25,50,100]}
            setPageSize={(pageSize: number) => {dispatch(setPageSize(pageSize))}}
            dashboard={false}
            shouldNavigate={true}
            />
        </Container>
      </PageContainer>
  )
}

export default withAuthenticationRequired(Eaters)
