/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PageContainer  from '../../components/PageContainer/PageContainer';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { AddPressContainer, AddPressTitle, AddPressContentHeader, AddPressContentBody, TopFields, MiddleFields1, MiddleFields2, MiddleFields3, MiddleFields4, MiddleFields5, BottomFields } from './Eaters.styled';
import {
  saveEater,
  setOpen,
  selectShouldOpen,
  selectMessage,
  selectSeverity,
  selectEater, fetchEater
} from '../Eater/slice';
import { AddEaterRequest, Eater } from './types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from 'react-router-dom';
import { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, {Dayjs} from 'dayjs'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@mui/material';



const ViewEater: React.FC = () => {
  const dispatch = useAppDispatch();
  const { eaterid } = useParams();
  const personFromServer = useAppSelector(selectEater);
  const [person, setPerson] = useState(personFromServer);

  useEffect(()=>{
    setPerson(personFromServer)
    setFirstName(personFromServer?.firstName);
    setLastName(personFromServer?.lastName);
    setEmail(personFromServer?.email);
    setDob(dayjs(personFromServer?.dob));
    setPhone(personFromServer?.phone);
    setAddressLine1(personFromServer?.addressLine1);
    setAddressLine2(personFromServer?.addressLine2);
    setCity(personFromServer?.city);
    setState(personFromServer?.state);
    setZipCode(personFromServer?.zipcode);
    setCountry(personFromServer?.country);
    setInstagram(personFromServer?.instagram);
    setFacebook(personFromServer?.facebook);
    setTwitter(personFromServer?.twitter);
    setTiktok(personFromServer?.tiktok);
    setPersona(personFromServer?.persona);
    setCuisineLikes(personFromServer?.cuisineLikes);
    setCuisineDislikes(personFromServer?.cuisineDislikes);
    setIngredientLikes(personFromServer?.ingredientLikes);
    setIngredientDislikes(personFromServer?.ingredientDislikes);
    setFavoriteReviewers(personFromServer?.favoriteReviewers);
    setGender(personFromServer?.gender);
    setIncomeLevel(personFromServer?.incomeLevel);
    setDiningFrequency(personFromServer?.diningFrequency);
    setTypeOfDiningEstablishment(personFromServer?.typeOfDiningEstablishment);
    setDietaryPreferences(personFromServer?.dietaryPreferences);
    setAllergies(personFromServer?.allergies);
    setSocialMediaActivity(personFromServer?.socialMediaActivity);
    setFavoriteRestaurants(personFromServer?.favoriteRestaurants);
    setOccupation(personFromServer?.occupation);
    setAmbienceDisliked(personFromServer?.ambienceDisliked);
    setFoodPreparationDisliked(personFromServer?.foodPreparationDisliked);
    setAmbienceLiked(personFromServer?.ambienceLiked);
    setFoodPreparationLiked(personFromServer?.foodPreparationLiked);
    setEmail(personFromServer?.email)
  },[
    personFromServer
  ])

  useEffect(() => {
    dispatch(fetchEater(eaterid??''));
  }, [dispatch])

  const[ firstName, setFirstName ] = useState(person?.firstName);
  const[ lastName, setLastName ] = useState(person?.lastName);
  const[ email, setEmail ] = useState(person?.email);
  const[ dob, setDob ] = useState<Dayjs | null>(dayjs(person?.dob));
  const[ phone, setPhone ] = useState(person?.phone);
  const[ addressLine1, setAddressLine1 ] = useState(person?.addressLine1);
  const[ addressLine2, setAddressLine2 ] = useState(person?.addressLine2);
  const[ city, setCity ] = useState(person?.city);
  const[ state, setState ] = useState(person?.state);
  const[ zipcode, setZipCode ] = useState(person?.zipcode);
  const[ country, setCountry ] = useState(person?.country);
  const[ instagram, setInstagram ] = useState(person?.instagram);
  const[ facebook, setFacebook ] = useState(person?.facebook);
  const[ twitter, setTwitter ] = useState(person?.twitter);
  const[ tiktok, setTiktok ] = useState(person?.tiktok);
  const[ persona, setPersona ] = useState(person?.persona);
  const[ cuisineLikes, setCuisineLikes ] = useState(person?.cuisineLikes);
  const[ cuisineDislikes, setCuisineDislikes ] = useState(person?.cuisineDislikes);
  const[ ingredientLikes, setIngredientLikes ] = useState(person?.ingredientLikes);
  const[ ingredientDislikes, setIngredientDislikes ] = useState(person?.ingredientDislikes);
  const[ favoriteReviewers, setFavoriteReviewers ] = useState(person?.favoriteReviewers);
  const[ gender, setGender ] = useState(person?.gender);
  const[ incomeLevel, setIncomeLevel ] = useState(person?.incomeLevel);
  const[ diningFrequency, setDiningFrequency ] = useState(person?.diningFrequency);
  const[ typeOfDiningEstablishment, setTypeOfDiningEstablishment ] = useState(person?.typeOfDiningEstablishment);
  const[ dietaryPreferences, setDietaryPreferences ] = useState(person?.dietaryPreferences);
  const[ allergies, setAllergies ] = useState(person?.allergies);
  const[ socialMediaActivity, setSocialMediaActivity ] = useState(person?.socialMediaActivity);
  const[ favoriteRestaurants, setFavoriteRestaurants ] = useState(person?.favoriteRestaurants);
  const[ occupation, setOccupation ] = useState(person?.occupation)
  const[ ambienceDisliked, setAmbienceDisliked ] = useState(person?.ambienceDisliked)
  const[ foodPreparationDisliked, setFoodPreparationDisliked] = useState(person?.foodPreparationDisliked)
  const[ ambienceLiked, setAmbienceLiked ] = useState(person?.ambienceLiked)
  const[ foodPreparationLiked, setFoodPreparationLiked] = useState(person?.foodPreparationLiked)
  const navigate = useNavigate();


  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: 'Eaters', 
      url:`/eaters`
    }
  ]

  const openServer = useAppSelector(selectShouldOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const handleGenderChange = (event: SelectChangeEvent) => {
    setGender(event.target.value as string);
  };
    
  return (
    <PageContainer title={person?.firstName+ " " + person?.lastName} breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
    <AddPressContainer>
      <AddPressContentHeader>
        <AddPressTitle>View / Update Eater</AddPressTitle>
          <Stack direction="row" spacing={2}>
            <Button variant="text" onClick={() => {
              const eater: AddEaterRequest = {
                firstName: firstName??'',
                lastName: lastName??'',
                email: email??'',
                dob: dob?.toDate(),
                phone: phone??'',
                addressLine1: addressLine1??'',
                addressLine2: addressLine2??'',
                city: city??'',
                state: state??'',
                zipcode: zipcode??'',
                country: country??'',
                instagram: instagram??'',
                facebook: facebook??'',
                twitter: twitter??'',
                tiktok: tiktok??'',
                persona: persona??'',
                cuisineLikes: cuisineLikes??'',
                cuisineDislikes: cuisineDislikes??'',
                ingredientLikes: ingredientLikes??'',
                ingredientDislikes: ingredientDislikes??'',
                favoriteReviewers: favoriteReviewers??'',
                gender: gender??'',
                incomeLevel: incomeLevel??'',
                diningFrequency: diningFrequency??'',
                typeOfDiningEstablishment: typeOfDiningEstablishment??'',
                dietaryPreferences: dietaryPreferences??'',
                allergies: allergies??'',
                socialMediaActivity: socialMediaActivity??'',
                favoriteRestaurants: favoriteRestaurants??'',
                occupation: occupation??'',
                ambienceDisliked: ambienceDisliked??'',
                foodPreparationDisliked: foodPreparationDisliked??'',
                ambienceLiked: ambienceLiked??'',
                foodPreparationLiked: foodPreparationLiked??''
              }
              dispatch(saveEater(eater));
              navigate('/eaters')
            }}>Save</Button>
            <Button variant='text' onClick={() => {
              navigate(`/eaters/${person?.eaterId}/dining-reviews`);
            }}>Reviews</Button>
          </Stack>
      </AddPressContentHeader>
      <AddPressContentBody>
        <TopFields>
          <Stack direction="row" spacing={5}>
              <TextField id="firstName" label="First Name" variant="outlined" value={firstName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(event.target.value);
              }}/>
              <TextField id="lastName" label="Last Name" variant="outlined" value={lastName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(event.target.value);
              }}/>
              <TextField id="email" label="Email" variant="outlined" value={email} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}/>
              <TextField id="phone" label="Phone" variant="outlined" value={phone} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(event.target.value);
              }}/>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date Of Birth"
                value={dob}
                onChange={(newValue: any) => {setDob(newValue)}}
              />
            </LocalizationProvider>
            </Stack>
          </TopFields>
          <MiddleFields1>
            <Stack direction="row" spacing={5}>
              <TextField id="addressLine1" label="Address Line 1" variant="outlined" value={addressLine1} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAddressLine1(event.target.value);
                    }}/>
              <TextField id="addressLine2" label="Address Line 2" variant="outlined" value={addressLine2} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAddressLine2(event.target.value);
                    }}/>
              <TextField id="city" label="City" variant="outlined" value={city} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCity(event.target.value);
                    }}/>
              <TextField id="state" label="State" variant="outlined" value={state} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState(event.target.value);
                    }}/>
              <TextField id="zipCode" label="Zip Code" variant="outlined" value={zipcode} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setZipCode(event.target.value);
                    }}/>
            </Stack>
            </MiddleFields1>
            <MiddleFields2>
              <Stack direction="row" spacing={5}>
              <TextField id="country" label="Country" variant="outlined" value={country} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCountry(event.target.value);
                    }}/>
              <TextField id="instagram" label="Instagram" variant="outlined" value={instagram} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setInstagram(event.target.value);
                    }}/>
              <TextField id="facebook" label="Facebook" variant="outlined" value={facebook} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFacebook(event.target.value);
                    }}/>
              <TextField id="twitter" label="Twitter" variant="outlined" value={twitter} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setTwitter(event.target.value);
                    }}/>
              <TextField id="tiktok" label="Tiktok" variant="outlined" value={tiktok} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setTiktok(event.target.value);
                    }}/>
              </Stack>
              </MiddleFields2>
              <MiddleFields3>
                <Stack direction="row" spacing={5}>
            <TextField id="cuisineLikes" label="Cuisine Likes" variant="outlined" value={cuisineLikes} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCuisineLikes(event.target.value);
                  }}/>
            <TextField id="cuisineDislikes" label="Cuisine Dislikes" variant="outlined" value={cuisineDislikes} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCuisineDislikes(event.target.value);
                  }}/>
            <TextField id="ingredientLikes" label="Ingredient Likes" variant="outlined" value={ingredientLikes} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIngredientLikes(event.target.value);
                  }}/>
            <TextField id="ingredientDislikes" label="Ingredient Dislikes" variant="outlined" value={ingredientDislikes} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIngredientDislikes(event.target.value);
                  }}/>
            <FormControl sx={{width: '7rem'}}>
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select
                labelId="gender-select-label"
                id="gender-simple-select"
                value={gender}
                label="Gender"
                onChange={handleGenderChange}
              >
                <MenuItem value={'Male'}>M</MenuItem>
                <MenuItem value={'Female'}>F</MenuItem>
              </Select>
              </FormControl>
            </Stack>
            </MiddleFields3>
            <MiddleFields4>
              <Stack direction="row" spacing={5}>
              <TextField id="favoriteReviewers" label="Favorite Reviewers" variant="outlined" value={favoriteReviewers} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFavoriteReviewers(event.target.value);
                  }}/>
            <TextField id="incomeLevel" label="Income Level" variant="outlined" value={incomeLevel} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIncomeLevel(event.target.value);
                  }}/>
            <TextField id="diningFrequency" label="Dining Frequency" variant="outlined" value={diningFrequency} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setDiningFrequency(event.target.value);
                  }}/>
            <TextField id="typeOfDiningEstablishment" label="Type of Dining Establishment" variant="outlined" value={typeOfDiningEstablishment} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTypeOfDiningEstablishment(event.target.value);
              }}/>
              <TextField id="dietaryPreferences" label="Dietary Preferences" variant="outlined" value={dietaryPreferences} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDietaryPreferences(event.target.value);
                    }}/>
          </Stack>
          </MiddleFields4>
          <MiddleFields5>
              <Stack direction="row" spacing={5}>
                <TextField id="ambienceLiked" label="Ambience Liked" variant="outlined" value={ambienceLiked} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAmbienceLiked(event.target.value);
                  }}/>
                <TextField id="ambienceDisliked" label="Ambience Disliked" variant="outlined" value={ambienceDisliked} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAmbienceDisliked(event.target.value);
                }}/>
                <TextField id="foodPreparationLiked" label="Food Preparation Liked" variant="outlined" value={foodPreparationLiked} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFoodPreparationLiked(event.target.value);
                }}/>
                <TextField id="foodPreparationDisliked" label="Food Preparation Disliked" variant="outlined" value={foodPreparationDisliked} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFoodPreparationDisliked(event.target.value);
                }}/>
              </Stack>
          </MiddleFields5>
          <BottomFields>
            <Stack direction="row" spacing={5}>
              <TextField id="allergies" label="Allergies" variant="outlined" value={allergies} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAllergies(event.target.value);
                    }}/>
              <TextField id="socialMediaActivity" label="Social Media Activity" variant="outlined" value={socialMediaActivity} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSocialMediaActivity(event.target.value);
                    }}/>
              <TextField id="favoriteRestaurants" label="Favorite Restaurants" variant="outlined" value={favoriteRestaurants} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFavoriteRestaurants(event.target.value);
                    }}/>
              <TextField id="occupation" label="Occupation" value={occupation} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setOccupation(event.target.value);
                    }}/>
              <TextField id="persona" label="Persona" sx={{width: '18rem'}} variant="outlined" value={persona} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPersona(event.target.value);
                    }}/>
            </Stack>
          </BottomFields>
        </AddPressContentBody>
      </AddPressContainer>
    </PageContainer>
  )
}

export default withAuthenticationRequired(ViewEater)
