import { createSlice, PayloadAction, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { RootState, store } from '../../redux/store';
import { AccessRequest, AccessResponse, AdminRequest, Admin } from './types';
import { okaoAxios } from '../../helpers/axios';
import { AlertColor } from '@mui/material/Alert';



export interface IAdminSlice {
  accessResponse: AccessResponse | undefined
  adminRequests: AdminRequest[] | undefined
  admins: Admin[] | undefined
  adminsCount: number
  adminRequestCount: number
  status: string
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined
  pageSize: number
}

const initialState: IAdminSlice = {
  status: 'idle',
  accessResponse: undefined,
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  adminRequests: undefined,
  pageSize: 100,
  adminRequestCount: 0,
  admins: undefined,
  adminsCount: 0
};

export const requestAdminAccess = createAsyncThunk('api/requestAdminAccess', async (req: AccessRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/request-access`, req)
  return response;
})

export const fetchAdminRequests = createAsyncThunk('api/fetchAdminRequests', async (page: number) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/access-request/${page}?size=${store.getState().admin.pageSize}`)
  return response;
})

export const fetchAdmins = createAsyncThunk('api/fetchAdmins', async (page: number) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/${page}?size=${store.getState().admin.pageSize}`)
  return response;
})

export const grantAdminAccess = createAsyncThunk('api/grantAdminAccess', async (req: AccessRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/grant-access`, req)
  return response;
})

export const declineAdminAccess = createAsyncThunk('api/declineAdminAccess', async (req: AccessRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/decline-access`, req)
  return response;
})

export const deleteAdmin = createAsyncThunk('api/deleteAdmin', async (req: AccessRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/delete`, req)
  return response;
})

export const updateAdmin = createAsyncThunk('api/updateAdmin', async (req: AccessRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).put(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin`, req)
  return response;
})

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(requestAdminAccess.fulfilled, (state, action) => {
      state.accessResponse = action.payload.data
      state.status = 'idle'
      state.shouldOpen = true;
      state.message = 'Successfully Requested'
      state.severity = 'success'
    }).addMatcher(isAnyOf(fetchAdminRequests.fulfilled, grantAdminAccess.fulfilled, declineAdminAccess.fulfilled), (state, action) => {
      state.adminRequests = action.payload.data
      state.adminRequestCount = action.payload.data.length
      state.status = 'idle'
    }).addMatcher(isAnyOf(fetchAdmins.fulfilled, deleteAdmin.fulfilled, updateAdmin.fulfilled), (state, action) => {
      state.admins = action.payload.data
      state.adminsCount = action.payload.data.length
      state.status = 'idle'
    }).addMatcher(isAnyOf(deleteAdmin.rejected, updateAdmin.rejected, requestAdminAccess.pending, fetchAdminRequests.pending, grantAdminAccess.pending, declineAdminAccess.pending), (state, action) => {
      state.status = 'pending'
    }).addMatcher(isAnyOf(deleteAdmin.rejected, updateAdmin.rejected, requestAdminAccess.rejected, fetchAdminRequests.rejected, grantAdminAccess.rejected, declineAdminAccess.rejected), (state, action) => {
      state.shouldOpen = true;
      state.message = 'Error occurred while performing a admin action'
      state.status = 'error'
      state.severity = 'error'
      console.log(action.error);
    })
  }
});

export const selectAccessResponse = (state: RootState): AccessResponse | undefined => state.admin.accessResponse
export const selectAdminRequests = (state: RootState): AdminRequest[] | undefined => state.admin.adminRequests
export const selectAdmins = (state: RootState): Admin[] | undefined => state.admin.admins
export const selectAdminRequestRowCount = (state: RootState): number => state.admin.adminRequestCount
export const selectAdminRowCount = (state: RootState): number => state.admin.adminsCount
export const selectShouldOpen = (state: RootState): boolean => state.admin.shouldOpen
export const selectMessage = (state: RootState): string | undefined => state.admin.message
export const selectSeverity = (state: RootState): AlertColor | undefined => state.admin.severity
export const selectIsLoading = (state: RootState): boolean => state.admin.status === "loading"
export const selectPageSize = (state: RootState): number => state.admin.pageSize

export const { setOpen, setPageSize } = adminSlice.actions;
export default adminSlice.reducer
