import styled from "styled-components";

export const Container = styled.div`
    width: 81.9375rem;
    height: 40.625rem;

    background: #FFFFFF;
    border-radius: 0.75rem;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`
export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 1.25rem;

    width: 78.875rem;
    height: 20.8125rem;
`
export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    gap: 52.875rem;

    width: 78.875rem;
    height: 1.8125rem;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`
export const MenuTitle = styled.div`
    width: 3.3125rem;
    height: 1.25rem;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    /* identical to box height */


    /* Text */

    color: #717178;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 0.4375rem;

    width: 40.5rem;
    height: 1.8125rem;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const LeftActions = styled.div`
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    gap: 0.625rem;

    width: 11rem;
    height: 1.8125rem;

    /* Text */

    border: 1px solid #717178;
    border-radius: 0.5rem;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const RightActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 0.75rem;

    width: 29.0625rem;
    height: 1.8125rem;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const GridContainer = styled.div`
    width: 100%;
    height: 50rem;
`