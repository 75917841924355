import axios, { Axios } from "axios";

const axiosInstance = axios.create({
    // baseURL: 'https://rsly-dis.resactly.com',
    timeout: 0,
    headers: {
        'Connection': 'keep-alive'
    }
});

export const okaoAxios = (authHeader: string): Axios => {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${authHeader}`;
    return axiosInstance;
}