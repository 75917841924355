import styled from "styled-components";

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    gap: 55.9375rem;

    width: 77.625;
    height: 3.625rem;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`

export const Title = styled.div`
    width: 10.4375rem;
    height: 1.5rem;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    /* Black/60 */

    color: #45464E;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 2rem;

    width: 77.625rem;
    height: 49.625rem;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    background-color: #fff;
    margin-top: 1rem;
`

export const AddPressContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 2rem;
    background-color: white;

    width: 77.625rem;
    height: 49.625rem;

    background-color: #fff;
    margin-top: 1rem;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const AddPressContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    gap: 55.9375rem;

    width: 77.625;
    height: 3.625rem;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`

export const AddPressTitle = styled.div`
    width: 10.4375rem;
    height: 1.5rem;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    /* Black/60 */

    color: #45464E;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const AddPressContentBody = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 4.8125rem;

    width: 73.9375rem;
    height: 44rem;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const TopFields = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 4.8125rem;

    width: 81.9375rem;
    height: 5rem;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    position: absolute;
    top: 5rem
`

export const MiddleFields1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 4.8125rem;

    width: 81.9375rem;
    height: 5rem;


    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;

    position: absolute;
    top: 10rem
`
export const MiddleFields2 = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0;
gap: 4.8125rem;

width: 81.9375rem;
height: 5rem;


/* Inside auto layout */

flex: none;
order: 3;
flex-grow: 0;

position: absolute;
top: 15rem
`

export const MiddleFields3 = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0;
gap: 4.8125rem;

width: 81.9375rem;
height: 5rem;


/* Inside auto layout */

flex: none;
order: 4;
flex-grow: 0;

position: absolute;
top: 20rem
`

export const MiddleFields4 = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0;
gap: 4.8125rem;

width: 81.9375rem;
height: 5rem;


/* Inside auto layout */

flex: none;
order: 5;
flex-grow: 0;

position: absolute;
top: 25rem
`

export const MiddleFields5 = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0;
gap: 4.8125rem;

width: 81.9375rem;
height: 5rem;


/* Inside auto layout */

flex: none;
order: 5;
flex-grow: 0;

position: absolute;
top: 30rem
`

export const BottomFields = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 4.8125rem;

    width: 81.9375rem;
    height: 5rem;


    /* Inside auto layout */

    flex: none;
    order: 3;
    flex-grow: 0;

    position: absolute;
    top: 35rem
`