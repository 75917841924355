import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.6875rem 0.9375rem;
    gap: 0.625rem;

    width: 26.479rem;
    height: 9.0625rem;

    background: #FFFFFF;
    border-radius: 0.5rem;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
`
export const ContentContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
padding: 0;
gap: 2rem;

width: 24.60rem;
height: 7.6875rem;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
`

export const Heading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    gap: 11.375rem;

    width: 24.60rem;
    height: 2rem;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 2rem;

    width: 24.60rem;
    height: 3.0625rem;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
`

export const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 0.5rem;

    width: auto;
    height: 3.0625rem;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
`

export const ItemContentName = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    /* Text */

    color: #717178;
    height: 1.0625rem
`
export const ItemContentValue = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5rem;
    display: flex;
    align-items: center;

    /* Primary */

    color: #1F2041;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`