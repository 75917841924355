import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { okaoAxios } from '../../helpers/axios';
import { RootState, store } from '../../redux/store';
import { MenuRequest, DishRequest } from './types'
import { NeedsMenuRequest, NewMenuRequest } from '../MissingMenu/types';


export interface IMenuSlice {
  status: string
  pageSize: number
}

const initialState: IMenuSlice = {
  status: 'idle',
  pageSize: 100
};

export const addMenu = createAsyncThunk('api/addMenu', async (nmr: NeedsMenuRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/menu/add-menu`, nmr));
  return response;
})

export const addNewMenu = createAsyncThunk('api/addMenu', async (nmr: NewMenuRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/menu/add-new-menu`, nmr));
  return response;
})

export const updateMenu = createAsyncThunk('api/updateMenu', async (menu: MenuRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).put(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/menu/${menu.menuId}`, menu));
  return response;
})

export const saveDish = createAsyncThunk('api/saveDish', async (dish: DishRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/menu/save-dish`, dish));
  return response;
})

export const deleteDish = createAsyncThunk('api/deleteDish', async (dishId: string) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).delete(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/menu/dish/${dishId}`));
  return response;
})

export const publishMenu = createAsyncThunk('api/publishMenu', async (menuId: string) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/menu/publish/${menuId}`));
  return response;
})

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
      setPageSize: (state, action: PayloadAction<number>) => {
        state.pageSize = action.payload
      }
  }
});

export const selectPageSize = (state: RootState): number => state.dashboard.pageSize
export const selectIsLoading = (state: RootState): boolean => state.dashboard.status === "loading"

export const { setPageSize } = menuSlice.actions;
export default menuSlice.reducer
