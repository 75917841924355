/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Container } from './OnboardingRestaurant.styled';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import PageContainer from '../../components/PageContainer/PageContainer';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import {

  GridColDef
} from '@mui/x-data-grid';
import { GridRowProps } from 'semantic-ui-react';
import Grid from '../../components/Grid/Grid'
import { Onboarding } from './types'
import {
  approveOnboardingRequest,
  declineOnboardingRequest,
  selectApproveRequestToggle,
  selectMessage,
  selectOnboardingRequests,
  selectPageSize,
  selectRowCount,
  selectSeverity,
  selectShouldOpen,
  setOpen,
  setPageSize
} from './slice';
import { fetchOnboardingRestaurantRequest } from './slice';
import { Button, CircularProgress } from '@mui/material';


const OnboardingRestaurant: React.FC = () => {
  const dispatch = useAppDispatch();
  const rowCount = useAppSelector(selectRowCount);
  const approveRequestToggle = useAppSelector(selectApproveRequestToggle);
  const pageSize = useAppSelector(selectPageSize);
  const openServer = useAppSelector(selectShouldOpen);

  const onboardingRequestsFromServer = useAppSelector(selectOnboardingRequests);
  const [onboardingRequests, setOnboardingRequests] = useState(onboardingRequestsFromServer);
  useEffect(() => {
    dispatch(fetchOnboardingRestaurantRequest(0));
  }, [approveRequestToggle])

  useEffect(()=>{
    setOnboardingRequests(onboardingRequestsFromServer);
  },[
    onboardingRequestsFromServer
  ]);

  const rows: GridRowProps[] = onboardingRequests.map((request: any) => {
    return {
            id: request.onboardRequestId,
            onboardRequestId: request.onboardRequestId,
            contactName: request.contactName,
            contactEmail: request.contactEmail,
            name: request.name,
            phone: request.phone,
            website: request.website
          }
  })

  const handleApproveRequest = (id: string): void => {
    dispatch(approveOnboardingRequest(id));
  }

  const handleDeclineRequest = (id: string): void => {
    dispatch(declineOnboardingRequest(id));
  }
  
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Restaurant Name', width: 150 },
    { field: 'contactName', headerName: 'Contact Name', width: 150 },
    { field: 'contactEmail', headerName: 'Contact Email', width: 250},
    { field: 'phone', headerName: 'Contact Phone', width: 150},
    { field: 'website', headerName: 'Website', width: 250},
    { field: 'action', headerName: 'Actions', width: 250, renderCell: (params): any => {
      return <div>
        <Button variant="text" data-testid="approve-button" onClick={() => handleApproveRequest(params.row.id)}>Approve</Button>
        <Button variant="text" data-testid="decline-button" onClick={() => handleDeclineRequest(params.row.id)}>Decline</Button>
      </div>
    }}
  ];

  const getRowID = (row: Onboarding): string => {
    return row.onboardRequestId;
  }

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  return (
      <PageContainer title='Onboarding Requests' open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
        <Container>
          <Grid 
            rows={rows} 
            setRows={onboardingRequests}
            totalRowCount={rowCount}
            dataColumns={columns}
            paginationServer={true}
            EditToolbar={null}
            empty={null}
            getRowId={getRowID}
            nextPageFn={(newPage: number) => dispatch(fetchOnboardingRestaurantRequest(newPage))}
            rowClickedFn={null}
            deleteFn={(id: string) => {/* do nothing */}}
            editable={false}
            pageSize={pageSize}
            saveFn={(newRow) => {
              /* do nothing */
            }}
            pageSizeOptions={[25,50,100]}
            setPageSize={(pageSize: number) => {dispatch(setPageSize(pageSize))}}
            dashboard={false}
            shouldNavigate={true}
            />
        </Container>
      </PageContainer>
  )
}

export default withAuthenticationRequired(OnboardingRestaurant);
