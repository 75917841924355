import { createAsyncThunk, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import { okaoAxios } from '../../helpers/axios';
import { RootState, store } from '../../redux/store';
import { Summary } from '../Restaurant/types';
import { Location, LocationRequest } from './types'
import { AlertColor } from '@mui/material/Alert';


export interface ILocationSlice {
  locations?: Location[] | undefined;
  status: string
  count: number
  pageSize: number
  page: number
  countByStatus: Summary[] | undefined
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined
}

const initialState: ILocationSlice = {
  locations: undefined,
  pageSize: 100,
  page: 0,
  status: 'idle',
  count: 0,
  countByStatus: undefined,
  shouldOpen: false,
  message: undefined,
  severity: undefined
};

export const fetchLocations = createAsyncThunk('api/fetchLocations', async (page: number) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/locations/${page}?size=${store.getState().location.pageSize}`)
  return response;
})

export const addLocation = createAsyncThunk('api/addLocation', async (request: LocationRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/locations`, request));
  return response;
})

export const startIngestion = createAsyncThunk('api/startIngestion', async () => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/ingestion`));
  return response;
})

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocations: (state, action: PayloadAction<Location[] | undefined>) => {
        state.locations = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    }
  },
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(fetchLocations.pending, addLocation.pending, startIngestion.pending), (state, action) => {
      state.status = 'loading'
    })
    .addMatcher(isAnyOf(fetchLocations.fulfilled, addLocation.fulfilled), (state, action) => {
      state.locations = action.payload.data
      state.count = action.payload.data.length
      state.status = 'idle'
      state.countByStatus = action.payload.data.countByStatus
    })
    .addMatcher(isAnyOf(fetchLocations.rejected, addLocation.rejected, startIngestion.rejected), (state, action) => {
      state.shouldOpen = true;
      state.message = 'Error occurred while performing a restaurant action'
      state.status = 'error'
      state.severity = 'error'
      console.log(action.error);
    })
  }
});

export const selectLocations = (state: RootState): Location[] => state.location.locations ?? []
export const selectRowCount = (state: RootState): number => state.location.count
export const selectPage = (state: RootState): number => state.location.page
export const selectPageSize = (state: RootState): number => state.location.pageSize
export const selectIsLoading = (state: RootState): boolean => state.location.status === "loading"
export const selectShouldOpen = (state: RootState): boolean => state.restaurant.shouldOpen
export const selectMessage = (state: RootState): string | undefined => state.restaurant.message
export const selectSeverity = (state: RootState): AlertColor | undefined => state.restaurant.severity

export const { setLocations, setPageSize, setPage, setOpen } = locationSlice.actions;
export default locationSlice.reducer
