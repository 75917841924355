/* eslint-disable */

import React, { useEffect } from 'react';
import PageContainer  from '../../components/PageContainer/PageContainer';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { AddPressContainer, AddPressContentHeader, AddPressContentBody } from './Eaters.styled';
import { selectEaterByEaterId, setOpen, selectShouldOpen, selectMessage, selectSeverity } from '../Eater/slice';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { fetchRestaurantsWithMenus, selectRestaurantWithMenuByAlias } from '../Restaurant/slice';

const DiningReview: React.FC = () => {
  const dispatch = useAppDispatch();
  const { eaterid, reviewid } = useParams();
  const eater = useAppSelector(selectEaterByEaterId(eaterid??''));
  const diningReview = eater?.reviews?.find(review => review.diningReviewId === reviewid)
  const restaurant = useAppSelector(selectRestaurantWithMenuByAlias(diningReview?.restaurantAlias??''));

  useEffect(() => {
    dispatch(fetchRestaurantsWithMenus())
  }, [dispatch])

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: 'Eaters', 
      url:`/eaters`
    },
    {
      name: eater?.firstName + " " + eater?.lastName??'', 
      url:`/eaters/${eater?.eaterId}`
    },
    {
      name: 'Dining Reviews', 
      url:`/eaters/${eaterid}/dining-reviews`
    }
  ]

  const openServer = useAppSelector(selectShouldOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const dishName = restaurant?.menus?.find(menu => menu.menuID === diningReview?.menuId)?.dishes.find(dish => dish.dishID === diningReview?.dishId)?.name;
    
  return (
    <PageContainer title={restaurant?.name} breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
    <AddPressContainer>
      <AddPressContentHeader>
        {dishName}
      </AddPressContentHeader>
      <AddPressContentBody>
        <Stack direction="column" spacing={5}>
          <div>
            {diningReview?.sentimentLabel}
          </div>
          <div>
            {diningReview?.sentimentScore}
          </div>
          <div>
            {diningReview?.sentimentSummary}
          </div>
          <div>
            {diningReview?.sentimentTags}
          </div>
          <div>
            {diningReview?.value}
          </div>
        </Stack>
        </AddPressContentBody>
      </AddPressContainer>
    </PageContainer>
  )
}

export default withAuthenticationRequired(DiningReview)
