/* eslint-disable */

import React, { useState} from 'react'
import './UserProfile.styled.ts';
import { Header, Icon, Table } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import PageContainer from '../../components/PageContainer/PageContainer';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useAppSelector } from '../../redux/hooks';
import { selectUserInfo } from '../../components/SecureRoute/slice';

const UserProfile: React.FC = () => {
  const user = useAppSelector(selectUserInfo);
  const {getIdTokenClaims } = useAuth0();
  const claims = getIdTokenClaims();

  const [open, setOpen] = useState(false);

    return (
      <PageContainer title='Profile' open={open} setOpen={setOpen}>
          <div>
            <Header as="h1">
              <Icon name="drivers license" />
              {' '}
              My User Profile (ID Token Claims)
              {' '}
            </Header>
            <p>
              Below is the information from your ID token which was obtained during the &nbsp;
              <a href="https://developer.okta.com/docs/guides/implement-auth-code-pkce">PKCE Flow</a>
              {' '}
              and is now stored in local storage.
            </p>
            <Table>
              <thead>
                <tr>
                  <th>Claim</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(user || {}).map((claimEntry) => {
                  const claimName: string = claimEntry[0];
                  const claimValue: any = claimEntry[1];
                  const claimId = `claim-${claimName}`;
                  return (
                    <tr key={claimName}>
                      <td>{claimName}</td>
                      <td id={claimId}>{claimValue.toString()}</td>
                    </tr>
                  );
                })}
                {Object.entries(claims || {}).map((claimEntry) => {
                  const claimName: string = claimEntry[0];
                  const claimValue: any = claimEntry[1];
                  const claimId = `claim-${claimName}`;
                  return (
                    <tr key={claimName}>
                      <td>{claimName}</td>
                      <td id={claimId}>{claimValue.toString()}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
      </PageContainer>
    )
}

export default withAuthenticationRequired(UserProfile)
