/* eslint-disable */

import React, {useEffect, useState} from 'react'
import PageContainer  from '../../components/PageContainer/PageContainer';
import { selectShouldOpen, selectMessage, setOpen, selectSeverity, startIngestion, setPage, selectLocations, selectPageSize, fetchLocations, selectRowCount, setPageSize, selectPage } from './slice';
import { generateTagsAndDescriptions } from '../Restaurant/slice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { 
        GridColumns, 
      } from '@mui/x-data-grid';
import { Container, ContentBody, ContentHeader, Title } from './Location.styled';
import { GridRowProps } from 'semantic-ui-react';
import Grid from '../../components/Grid/Grid'
import { EditToolbar } from '../../components/EditToolbar/EditToolbar';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { withAuthenticationRequired } from '@auth0/auth0-react';


const Location: React.FC = () => {
  const dispatch = useAppDispatch();
  const locationsServer = useAppSelector(selectLocations);
  const [locations, setLocations] = useState(locationsServer);
  const rowCount = useAppSelector(selectRowCount);
  const pageSize = useAppSelector(selectPageSize);
  const currPage = useAppSelector(selectPage);

  useEffect(() => {
    dispatch(fetchLocations(0));
  }, [])

  useEffect(()=>{
    setLocations(locationsServer);
  },[locationsServer]);

  const rows: GridRowProps[] = locations.map(location => {
    return {
            id: location.locationID,
            locationID: location.locationID,
            city: location.city,
            state: location.state,
            zipCode: location.zipCode,
            country: location.country,
            query: (location.query==='' || location.query===null)?location.zipCode:location.query,
            nextProcessedDate: location.nextProcessedDate,
            count: location.count
          }
  })

  const getRowID = (row: any) => {
    return row.id;
  }

  const id = randomId();
  const empty = {
    id,
    locationID: id,
    city: '',
    state: '',
    zipCode: '',
    country: '',
    query: null,
    nextProcessedDate: '',
    count: 0
  }
  
  const columns: GridColumns = [
    { field: 'city', headerName: 'City', width: 100 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'zipCode', headerName: 'Zip Code', width: 100 },
    { field: 'country', headerName: 'Country', width: 150 },
    { field: 'query', headerName: 'Query', width: 150 },
    { field: 'count', headerName: 'Number Processed', width: 150 },
    { field: 'nextProcessedDate', headerName: 'Next Processed Date', width: 150 },
  ];

  const openServer = useAppSelector(selectShouldOpen);
  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);
    
  return (
      <PageContainer title='Locations' open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
        <Container>
          <ContentHeader>
            <Title>Details</Title>
            <Stack direction="row" spacing={2}>
              <Button variant="text" onClick={() => {
                dispatch(startIngestion());
              }}>Start Ingestion</Button>
              <Button variant="text" onClick={() => {
                dispatch(generateTagsAndDescriptions());
              }}>Generate Tags & Descriptions</Button>
              <Button variant="text" onClick={() => {
                dispatch(fetchLocations(currPage));
              }}>Refresh</Button>
            </Stack>
        </ContentHeader>
        <ContentBody>
            <Grid 
              rows={rows} 
              totalRowCount={rowCount}
              dataColumns={columns} 
              editable={false}
              saveFn={(newRow) => {
                /* do nothing */
              }}
              paginationServer={true}
              nextPageFn={(newPage: number) => {setPage(newPage); dispatch(fetchLocations(newPage))}}
              rowClickedFn={()=> {/*do nothing}*/}}
              deleteFn={(id: string) => {/* do nothing */}}
              setRows={setLocations}
              EditToolbar={EditToolbar}
              empty={empty}
              shouldNavigate={true}
              route='/locations/add-location'
              getRowId={getRowID}
              pageSize={pageSize}
              pageSizeOptions={[25,50,100]}
              setPageSize={(pageSize: number) => {dispatch(setPageSize(pageSize))}}
              />
            </ContentBody>
        </Container>
      </PageContainer>
  )
}

export default withAuthenticationRequired(Location)
