import styled from 'styled-components'

export const Container = styled.div`
  height: 40.625rem;
  width: 81.9375rem;
  background-color: white;
  position: absolute;
  top:15.75rem;
  border-radius: 0.5rem;
`

export const Summary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 1.1875rem;

  position: absolute;
  width: 81.8125rem;
  height: 9.0625rem;
  top: -13.25rem;
`