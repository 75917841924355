import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { okaoAxios } from '../../helpers/axios';
import { store } from '../../redux/store';
import { AddPressRequest } from './types'
import { AlertColor } from '@mui/material/Alert';


export interface IReviewSlice {
  status: string,
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined,
}

const initialState: IReviewSlice = {
  status: 'idle',
  shouldOpen: false,
  message: undefined,
  severity: undefined,
};

export const addPress = createAsyncThunk('api/addPress', async (nmr: AddPressRequest) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants/add-press`, nmr));
  return response;
})


export const reviewsSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
  
  }
});

export default reviewsSlice.reducer
