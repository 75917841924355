import { PayloadAction, createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit';
import { okaoAxios } from '../../helpers/axios';
import { RootState, store } from '../../redux/store';
import { UnblockUser } from './types';
import { AlertColor } from '@mui/material/Alert';

export interface IOnboardingSlice {
    status: string,
    users: UnblockUser[] | [],
    count: number,
    pageSize: number,
    shouldOpen: boolean
    message: string | undefined
    severity: AlertColor | undefined,
}

const initialState: IOnboardingSlice = {
    status: 'idle',
    users: [],
    pageSize: 100,
    count: 0,
    shouldOpen: false,
    message: undefined,
    severity: undefined,
}

export const fetchUnblockUsers = createAsyncThunk('api/unblock-users', async (page: number) => {
    const auth = store.getState().secure.accessToken ??'';
    const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/unblock-requests/${page}?size=${store.getState().unblockuser.pageSize}`);
    return response;
});

export const approveUnblockRequest = createAsyncThunk('api/approveUnblockRequest', async (id: string) => {
    const auth = store.getState().secure.accessToken ??'';
    const response = await okaoAxios(auth).put(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/unblock-user-account/${id}`);
    return response;
});

export const declineUnblockRequest = createAsyncThunk('api/declineUnblockRequest', async (id: string) => {
    const auth = store.getState().secure.accessToken ??'';
    const response = await okaoAxios(auth).put(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/admin/decline-unblock-request/${id}`);
    return response;
});


export const unblockUserSlice = createSlice({
    name: 'unblockuser',
    initialState,
    reducers: {
        setPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload
        },
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.shouldOpen = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchUnblockUsers.fulfilled, (state, action) => {
            state.users = action.payload.data.result;
            state.status = 'idle';
            state.count = state.users.length;
        })
        .addMatcher(isAnyOf(approveUnblockRequest.fulfilled, declineUnblockRequest.fulfilled), (state, action) => {
            state.message = action.payload.data.message;
            state.severity = "success";
            state.shouldOpen = true;
        })
        .addMatcher(isAnyOf(fetchUnblockUsers.pending, approveUnblockRequest.pending, declineUnblockRequest.pending), (state, action) => {
            state.status = 'loading';
        })
        .addMatcher(isAnyOf(fetchUnblockUsers.rejected, approveUnblockRequest.rejected, declineUnblockRequest.rejected), (state, action) => {
            state.shouldOpen = true;
            state.status = "error";
            state.severity = 'error'
            state.message = 'Error occurred while performing an unblocking action'
        })
    }
})

export const selectUnblockUsers = (state: RootState): any => state.unblockuser.users?? [];
export const selectRowCount = (state: RootState): number => state.unblockuser.count;
export const selectSeverity = (state: RootState): AlertColor | undefined => state.unblockuser.severity;
export const selectPageSize = (state: RootState): number => state.unblockuser.pageSize;
export const selectMessage = (state: RootState): string | undefined => state.unblockuser.message;
export const selectShouldOpen = (state: RootState): boolean => state.unblockuser.shouldOpen;

export const {setPageSize, setOpen} = unblockUserSlice.actions;
export default unblockUserSlice.reducer;