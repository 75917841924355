import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';


export interface IDashboardSlice {
  status: string
  pageSize: number
}

const initialState: IDashboardSlice = {
  status: 'idle',
  pageSize: 25
};


export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    }
  }
});

export const selectPageSize = (state: RootState): number => state.dashboard.pageSize
export const selectIsLoading = (state: RootState): boolean => state.dashboard.status === "loading"

export const { setPageSize } = dashboardSlice.actions;
export default dashboardSlice.reducer
