import { configureStore } from '@reduxjs/toolkit';
import { counterReducer } from '../components/Counter/slice';
import secureReducer from '../components/SecureRoute/slice'
import dashboardReducer from '../pages/Dashboard/slice'
import menuReducer from '../pages/Menu/slice'
import missingMenuReducer from '../pages/MissingMenu/slice'
import restaurantReducer from '../pages/Restaurant/slice'
import locationReducer from '../pages/Location/slice'
import reviewReducer from '../pages/Reviews/slice'
import eaterReducer from '../pages/Eater/slice'
import adminReducer from '../pages/Admin/slice'
import onboardingRequestReducer from '../pages/OnbordingRestaurantRequests/slice';
import unblockUserReducer from "../pages/UnblockUsers/slice";
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  counter: counterReducer,
  secure: secureReducer,
  dashboard: dashboardReducer,
  missingMenu: missingMenuReducer,
  menu: menuReducer,
  restaurant: restaurantReducer,
  location: locationReducer,
  reviews: reviewReducer,
  eater: eaterReducer,
  admin: adminReducer,
  onboarding: onboardingRequestReducer,
  unblockuser: unblockUserReducer
})


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const configureAppStore = (initialState = {}) => {

  const store = configureStore({
                  reducer: rootReducer, 
                  preloadedState: initialState,
                  devTools: process.env.NODE_ENV !== 'production'
                }
              )

  return store;
};

export const store = configureAppStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof configureAppStore>;

