/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PageContainer  from '../../components/PageContainer/PageContainer';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { AddPressContainer, AddPressTitle, AddPressContentHeader, AddPressContentBody } from './Eaters.styled';
import { addDiningReview, setOpen, selectShouldOpen, selectMessage, selectSeverity, fetchEaters } from '../Eater/slice';
import { fetchRestaurantsWithMenus, selectRestaurantsWithMenus } from '../Restaurant/slice';
import { AddDiningReviewRequest } from './types'
import { Menu } from '../Menu/types'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from 'react-router-dom';
import { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { selectEaterByEaterId } from './slice';
import { Restaurant } from '../Restaurant/types';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@mui/material';

const AddPress: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const[ alias, setAlias ] = useState('');
  const[ menuId, setMenuId ] = useState('');
  const[ dishId, setDishId ] = useState('');
  const[ value, setValue ] = useState('');
  const { eaterid } = useParams();
  const eater = useAppSelector(selectEaterByEaterId(eaterid??''));
  const restaurants = useAppSelector(selectRestaurantsWithMenus)

  useEffect(() => {
    dispatch(fetchRestaurantsWithMenus())
  }, [dispatch])

  const restaurantsWithDishes: Restaurant[] = [];
  restaurants.forEach(restaurant => {
    const menus :Menu[] = restaurant.menus??[];
    menus.forEach(menu => {
      if(menu.dishes.length > 0){
        if(restaurantsWithDishes.find(dR => dR.alias === restaurant.alias) === undefined){
          restaurantsWithDishes.push(restaurant);
        }
      }
    })
  })
  restaurantsWithDishes.sort((resA, resB) => resA.name > resB.name ? 1 : -1)

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: 'Eaters', 
      url:`/eaters`
    },
    {
      name: eater?.firstName + " " + eater?.lastName, 
      url:`/eaters/${eaterid}`
    },
    {
      name: 'Dining Reviews', 
      url:`/eaters/${eaterid}/dining-reviews`
    }
  ]

  const openServer = useAppSelector(selectShouldOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const handleRestaurantChange = (event: SelectChangeEvent) => {
    setAlias(event.target.value as string);
  };

  const handleMenuChange = (event: SelectChangeEvent) => {
    setMenuId(event.target.value as string);
  };

  const handleDishChange = (event: SelectChangeEvent) => {
    setDishId(event.target.value as string);
  };

  let menus = [ ...restaurantsWithDishes].find(restaurant => restaurant.alias === alias)?.menus?.filter(menu => menu.dishes.length > 0)??[];
  if(menus.length > 1){
    menus.sort((m1,m2) => m1.name > m2.name ? 1 : -1);
  }
  const menusEle = menus.map(menu => 
      <MenuItem value={menu.menuID}>{menu.name}</MenuItem>);
  
  let dishes = [...menus]?.find(menu => menu.menuID === menuId)?.dishes??[];
  if(dishes.length > 1){
    dishes = [...dishes].sort((d1,d2) => d1.name > d2.name ? 1 : -1);
  }
  const dishesEle = dishes?.map(dish => 
    <MenuItem value={dish.dishID}>{dish.name} {dish.price ? `- ${dish.price}` : ''}</MenuItem>)
    
  return (
    <PageContainer title="Add Review" breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
    <AddPressContainer>
      <AddPressContentHeader>
        <AddPressTitle>Add Review</AddPressTitle>
          <Stack direction="row" spacing={2}>
            <Button variant="text" onClick={() => {
              const addReviewRequest: AddDiningReviewRequest = {
                eaterId: eater?.eaterId??'',
                restaurantAlias: alias,
                menuId: menuId,
                dishId: dishId,
                value: value
              }
              dispatch(addDiningReview(addReviewRequest));
              navigate(`/eaters/${eaterid}/dining-reviews`)
            }}>Save</Button>
          </Stack>
      </AddPressContentHeader>
      <AddPressContentBody>
      <Stack direction='column' spacing={5} sx={{width: '73rem'}}>
        <FormControl fullWidth>
            <InputLabel id="restaurant-select-label">Restaurant</InputLabel>
            <Select
              labelId="restaurant-select-label"
              id="restaurant-simple-select"
              value={alias}
              label="Restaurant"
              onChange={handleRestaurantChange}
            >
              {restaurantsWithDishes.map(
                restaurant => 
                <MenuItem value={restaurant.alias}>
                  {restaurant.name}
                </MenuItem>)}
            </Select>
        </FormControl>
        <FormControl fullWidth>
            <InputLabel id="menu-select-label">Menu</InputLabel>
            <Select
              labelId="menu-select-label"
              id="menu-simple-select"
              value={menuId}
              label="Menu"
              onChange={handleMenuChange}
            >
              {menusEle}
            </Select>
        </FormControl>
        <FormControl fullWidth>
            <InputLabel id="dish-select-label">Dish</InputLabel>
            <Select
              labelId="dish-select-label"
              id="dish-simple-select"
              value={dishId}
              label="Dish"
              onChange={handleDishChange}
            >
              {dishesEle}
            </Select>
        </FormControl>
        <TextField id="value" sx={{width: '73rem'}} label="Dining Review" multiline rows={4} value={value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setValue(event.target.value);
                    }}/>
        </Stack>
        </AddPressContentBody>
      </AddPressContainer>
    </PageContainer>
  )
}

export default withAuthenticationRequired(AddPress)
