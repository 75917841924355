/* eslint-disable */

import React, {useState} from 'react'
import PageContainer  from '../../components/PageContainer/PageContainer';
import { addLocation, selectPageSize, selectPage } from './slice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { Container, Title, ContentHeader, ContentBody } from './Location.styled';
import { LocationRequest } from './types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { BreadCrumbLink } from '../../components/PageContainer/PageContainer';

const AddLocation: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currPageSize = useAppSelector(selectPageSize);
  const currPage = useAppSelector(selectPage);
  const[ city, setCity ] = useState('');
  const[ state, setState ] = useState('');
  const[ zipCode, setZipCode ] = useState('');
  const[ country, setCountry ] = useState('');
  const[ query, setQuery ] = useState('');

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: 'Locations', 
      url:`/locations`
    }
  ]

  const [open, setOpen] = useState(false);
    
  return (
    <PageContainer title="Add Location" breadcrumbs={breadcrumbLinks} open={open} setOpen={setOpen}>
    <Container>
      <ContentHeader>
        <Title>Details</Title>
          <Stack direction="row" spacing={2}>
            <Button variant="text" onClick={() => {
              const request: LocationRequest = {
                city: city??'',
                state: state??'',
                country: country??'',
                zipCode: zipCode,
                query: query??'',
                page: currPage,
                pageSize: currPageSize
              }
              dispatch(addLocation(request))
              navigate('/locations');
            }}>Save</Button>
          </Stack>
      </ContentHeader>
      <ContentBody>
        <Stack direction="row" spacing={5}>
          <TextField id="city" label="City" variant="outlined" value={city} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCity(event.target.value);
          }}/>
          <TextField id="state" label="State" variant="outlined" value={state} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setState(event.target.value);
          }}/>
          <TextField id="zipCode" label="Zip Code" variant="outlined" value={zipCode} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setZipCode(event.target.value);
          }}/>
          <TextField id="country" label="Country" variant="outlined" value={country} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCountry(event.target.value);
          }}/>
          <TextField id="query" label="Query" variant="outlined" value={query} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(event.target.value);
          }}/>
        </Stack>
        </ContentBody>
      </Container>
    </PageContainer>
  )
}

export default AddLocation
