/* eslint-disable */

import React, {useEffect, useState} from 'react'
import PageContainer  from '../../components/PageContainer/PageContainer';
import { 
          selectPageSize,
          setPageSize } from './slice';
import { selectNewRestaurantCount, 
  selectAdminRestaurants, 
  selectRowCount,
  selectShouldOpen,
  selectMessage,
  selectSeverity, 
  setCurrentPage,
  selectCurrentPage,
  selectIsLoading,
  fetchRestaurantsAdminDashboard
  } from '../Restaurant/slice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { EditToolbar } from '../../components/EditToolbar/EditToolbar';
import {
        GridColumns, 
        GridRowModel,
        GridEventListener,
        GridRowParams,
        MuiEvent,
        GridCallbackDetails
      } from '@mui/x-data-grid';
import { GridRowProps } from 'semantic-ui-react';
import { Container, Summary } from './Dashboard.styled';
import { useNavigate } from 'react-router-dom';
import DashboardSummary, { Item } from '../../components/DashboardSummary/DashboardSummary';
import Grid from '../../components/Grid/Grid'
import { Restaurant } from '../Restaurant/types';
import { deleteRestaurant,
          selectNeedsWebsiteCount,
          selectNeedsTagsAndDescriptions, 
          selectCountByStatus, 
          selectTopCities,
          setOpen,
          saveRestaurant   } from '../Restaurant/slice';
import {
  randomId,
} from '@mui/x-data-grid-generator';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const Dashboard: React.FC = () => {
  const openServer = useAppSelector(selectShouldOpen);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const restaurantFromServer = useAppSelector(selectAdminRestaurants);
  const pageSize = useAppSelector(selectPageSize)
  const [restaurants, setRestaurants] = useState(restaurantFromServer);
  const rowCount = useAppSelector(selectRowCount);
  const newRestaurantCount = useAppSelector(selectNewRestaurantCount);
  const needsWebsiteCount = useAppSelector(selectNeedsWebsiteCount);
  const needsTagsAndDescriptions = useAppSelector(selectNeedsTagsAndDescriptions);
  const countByStatus = useAppSelector(selectCountByStatus);
  const topCities = useAppSelector(selectTopCities);
  const currentPage = useAppSelector(selectCurrentPage);
  
  useEffect(()=>{
    setRestaurants(restaurantFromServer)
  },[
    restaurantFromServer
  ])

  const handleRestaurantClick: GridEventListener<'rowClick'> = (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>,
    details: GridCallbackDetails
  ) => {
    const outerHTML = (event.target as any).outerHTML
    if(outerHTML.indexOf('div') === 1){
      navigate(`/restaurant/${params.row.alias}`)
    }
  };

  useEffect(() => {
      dispatch(fetchRestaurantsAdminDashboard(currentPage));
  }, [dispatch])

  const rows: GridRowProps[] = restaurants.map(restaurant => {
    return {
            id: restaurant.restaurantID,
            restaurantID: restaurant.restaurantID,
            name: restaurant.name,
            date: restaurant.created,
            menu: restaurant.menuCount,
            location: restaurant.location,
            status: restaurant.status,
            alias: restaurant.alias,
            instagram: restaurant.instagram,
            facebook: restaurant.facebook,
            verified: restaurant.verified,
            imageurl: restaurant.imageurl
          }
  })
  
  const columns: GridColumns = [
    { field: 'name', headerName: 'Restaurant Name', width: 250 },
    { field: 'date', headerName: 'Created Date', width: 150 },
    { field: 'menu', headerName: 'Menus', width: 100},
    { field: 'instagram', headerName: 'Instagram', width: 150},
    { field: 'facebook', headerName: 'Facebook', width: 150},
    { field: 'location', headerName: 'Location', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'verified', headerName: 'Verified', width: 150, editable: false },
  ];

  const newRestaurants: Item[] = [
    {
      name: "New Restaurants",
      value: ''+newRestaurantCount
    },
    {
      name: "Needs Website",
      value: ''+needsWebsiteCount
    },
    {
      name: "Needs Tags & Descriptions",
      value: ''+needsTagsAndDescriptions
    }
  ]

  const location: Item[] = topCities?.map((city) => {
    const item: Item = {
                        name: city.name,
                        value: ''+city.count
                      }
      return item;
    })??[]

  const statuses: Item[] = countByStatus?.map((city) => {
    const item: Item = {
                        name: city?.name,
                        value: ''+city?.count
                      }
      return item;
    })??[]

  const id = randomId()
  const empty = {focusField: 'name', fields: { 
    id,
    restaurantID: id,
    name: '',
    date: '',
    menu: 0,
    location: '',
    status: '',
    alias: '',
    instagram: '',
    facebook: '',
    verified: '',
    imageurl: ''
  }
  }

  const getRowID = (row: Restaurant): string => {
    return row.restaurantID;
  }
    
  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);
  const isLoading = useAppSelector(selectIsLoading);

  return (
      <PageContainer title='Dashboard' open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Container>
        <Summary>
          <DashboardSummary items={newRestaurants}/>
          <DashboardSummary items={location}/>
          <DashboardSummary items={statuses}/>
        </Summary>
          <Grid 
            rows={rows} 
            setRows={setRestaurants}
            totalRowCount={rowCount}
            dataColumns={columns}
            paginationServer={true}
            EditToolbar={EditToolbar}
            empty={empty}
            getRowId={getRowID}
            nextPageFn={(newPage: number) => {dispatch(setCurrentPage(newPage)); dispatch(fetchRestaurantsAdminDashboard(newPage))}}
            rowClickedFn={handleRestaurantClick}
            deleteFn={(id: string) => dispatch(deleteRestaurant(id))}
            editable={false}
            pageSize={pageSize}
            saveFn={() => {/*do nothing*/}}
            pageSizeOptions={[25,50,100]}
            setPageSize={(pageSize: number) => {dispatch(setPageSize(pageSize)); dispatch(fetchRestaurantsAdminDashboard(currentPage))}}
            dashboard={true}
            />
        </Container>
      </PageContainer>
  )
}

export default withAuthenticationRequired(Dashboard);
