import * as React from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { GridRowProps } from 'semantic-ui-react';
import {
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
} from '@mui/x-data-grid';
import { renderAvatar } from '../Grid/cell-renderers/avatar';

interface Props {
  rows: GridRowProps[],
  totalRowCount: number,
  setRows: any,
  dataColumns: GridColumns,
  EditToolbar: any,
  empty: any,
  getRowId: any,
  saveFn: (r: any) => void,
  deleteFn: (r: any) => void,
  setPageSize: (r: any) => void,
  nextPageFn: any,
  rowClickedFn: any,
  paginationServer: boolean,
  editable: boolean,
  pageSize: number,
  pageSizeOptions: number[]
  shouldNavigate?: boolean
  route?: string
  dashboard?: boolean
  type?: string
};

const FullFeaturedCrudGrid: React.FC<Props> = ({
  rows,
  setRows,
  totalRowCount,
  dataColumns,
  EditToolbar,
  empty,
  getRowId,
  saveFn,
  deleteFn,
  setPageSize,
  nextPageFn,
  rowClickedFn,
  paginationServer,
  editable,
  pageSize,
  pageSizeOptions,
  shouldNavigate,
  route,
  dashboard,
  type
}: Props): JSX.Element => {
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ): void => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    deleteFn(id);
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    // eslint-disable-next-line
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel): GridRowModel => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    saveFn(updatedRow);
    return updatedRow;
  };

  let columns: GridColumns = dataColumns;
  const actionColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            // eslint-disable-next-line
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
            // eslint-disable-next-line
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
          ];
        }

        return [
          // eslint-disable-next-line
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          // eslint-disable-next-line
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const imageColumn: GridColumns = [
    {
      field: 'image',
      headerName: 'Image',
      renderCell: renderAvatar,
      sortable: false,
      filterable: false,
      width: 180
    }]
  columns = imageColumn.concat(dataColumns);
  if(editable){
    columns = actionColumns.concat(columns);
  }

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      { !paginationServer && <DataGrid
        columnBuffer={9}
        rows={rows}
        rowCount={totalRowCount}
        columns={columns}
        editMode="row"
        rowsPerPageOptions={pageSizeOptions}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel, empty, shouldNavigate, route, dashboard },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{
          pagination: {
            pageSize,
            page: 0
          }
        }}
        pagination
        autoHeight={true}
        onPageSizeChange={(size) => {setPageSize(size)}}
        getRowId={getRowId}
        onRowClick={rowClickedFn}
      />}
      { paginationServer && <DataGrid
        columnBuffer={9}
        rows={rows}
        rowCount={totalRowCount}
        columns={columns}
        editMode="row"
        rowsPerPageOptions={pageSizeOptions}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel, empty, shouldNavigate, route, dashboard },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{
          pagination: {
            pageSize,
            page: 0
          }
        }}
        pagination
        paginationMode="server"
        autoHeight={true}
        onPageSizeChange={(size) => {setPageSize(size)}}
        onPageChange={(newPage) => nextPageFn(newPage)}
        getRowId={getRowId}
        onRowClick={rowClickedFn}
      />}
    </Box>
  );
}

export default FullFeaturedCrudGrid;