/* eslint-disable */

import React from 'react'
import PageContainer  from '../../components/PageContainer/PageContainer';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { AddPressContainer, AddPressContentHeader, AddPressContentBody } from './Reviews.styled';
import { selectCurrentRestaurant, setOpen, selectShouldOpen, selectMessage, selectSeverity } from '../Restaurant/slice';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const PressDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const { alias, pressID } = useParams();
  const restaurant = useAppSelector(selectCurrentRestaurant);
  const press = restaurant?.press?.find(press => press.id === pressID)

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: restaurant?.name??'', 
      url:`/restaurant/${alias}`
    },
    {
      name: 'Press', 
      url:`/restaurant/${alias}/reviews`
    }
  ]

  const openServer = useAppSelector(selectShouldOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }
    
  return (
    <PageContainer title="Press Detail" breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
    <AddPressContainer>
      <AddPressContentHeader>
        {press?.entity}
      </AddPressContentHeader>
      <AddPressContentBody>
        <Stack direction="column" spacing={5}>
          <div>
            {press?.sentimentLabel}
          </div>
          <div>
            {press?.sentimentScore}
          </div>
          <div>
            {press?.sentimentSummary}
          </div>
          <div>
            {press?.sentimentTags}
          </div>
          <div>
            {press?.blurb}
          </div>
        </Stack>
        </AddPressContentBody>
      </AddPressContainer>
    </PageContainer>
  )
}

export default withAuthenticationRequired(PressDetail)
