/* eslint-disable */

import React, {useEffect, useState} from 'react'
import { Container, ContentHeader, Title } from './Menu.styled';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectCurrentRestaurant, selectMessage, setOpen, selectSeverity, selectShouldOpen } from '../Restaurant/slice';
import PageContainer, { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { publishMenu, updateMenu } from './slice';
import { MenuRequest } from './types';
import DishGrid from '../../components/DishGrid/DishGrid';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const Menu: React.FC = () => {
  const { alias, menuID } = useParams();
  const restaurant = useAppSelector(selectCurrentRestaurant);
  const [ menu, setMenu ] = useState(restaurant?.menus?.find(m => m.menuID === menuID))
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: restaurant?.name??'', 
      url:`/restaurant/${alias}`
    }
  ]

  useEffect(() => {
    setMenu(restaurant?.menus?.find(m => m.menuID === menuID));
  }, [restaurant])

  const [itemParser, setItemParser] = useState(menu?.itemParser);
  const [titleParser, setTitleParser] = useState(menu?.titleParser);
  const [ingredientParser, setIngredientParser] = useState(menu?.ingredientParser);
  const [priceParser, setPriceParser] = useState(menu?.priceParser);
  const [descriptionParser, setDescriptionParser] = useState(menu?.descriptionParser);
  const [menuUrl, setMenuUrl ] = useState(menu?.url)

  const openServer = useAppSelector(selectShouldOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

    return (
      <PageContainer title={menu?.name} breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
          <Container>
            <ContentHeader>
              <Title>Details</Title>
              <Stack direction="row" spacing={2}>
                <Button variant="text" onClick={() => {
                  if(menu){
                    const mr: MenuRequest = {
                      menuId: menu.menuID,
                      name: menu.name,
                      url: menuUrl??'',
                      itemParser: itemParser??'',
                      titleParser: titleParser??'',
                      descriptionParser: descriptionParser??'',
                      priceParser: priceParser??'',
                      ingredientParser: ingredientParser??'',
                      dishes: menu.dishes
                    };
                    dispatch(updateMenu(mr));
                  }
                }}>Update</Button>
                <Button variant='text' onClick={() => {
                  if(menu){
                    dispatch(publishMenu(menu.menuID));
                  }
                }}>Publish Menu</Button>
                <Button variant='text' onClick={() => {
                  if(menu){
                    navigate(`/restaurant/${alias}/menu/${menuID}/add-dish`)
                  }
                }}>Add Dish</Button>
              </Stack>
            </ContentHeader>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField id="item_parser" label="Item Parser" variant="standard" value={itemParser} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setItemParser(event.target.value);
              }}/>
              <TextField id="title_parser" label="Title Parser" variant="standard" value={titleParser} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTitleParser(event.target.value);
              }}/>
              <TextField id="description_parser" label="Description Parser" variant="standard" value={descriptionParser} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDescriptionParser(event.target.value);
              }}/>
              <TextField id="ingredient_parser" label="Ingredient Parser" variant="standard" value={ingredientParser} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIngredientParser(event.target.value);
              }}/>
              <TextField id="price_parser" label="Price Parser" variant="standard" value={priceParser} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPriceParser(event.target.value);
              }}/>
              <TextField id="menu" label="Menu Url" variant="standard" value={menuUrl} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMenuUrl(event.target.value);
              }}/>
            </Box>
            <DishGrid menu={menu}/>
        </Container>
      </PageContainer>
    )
}

export default withAuthenticationRequired(Menu)
