/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Container } from '../OnbordingRestaurantRequests/OnboardingRestaurant.styled';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import PageContainer from '../../components/PageContainer/PageContainer';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import {

  GridColDef
} from '@mui/x-data-grid';
import { GridRowProps } from 'semantic-ui-react';
import Grid from '../../components/Grid/Grid'
import {  selectMessage, selectUnblockUsers, selectPageSize, selectRowCount, selectSeverity, selectShouldOpen, setOpen, setPageSize, fetchUnblockUsers, approveUnblockRequest, declineUnblockRequest } from './slice';
import { Button } from '@mui/material';
import { UnblockUser } from './types';


const UnblockUsers: React.FC = () => {
  const dispatch = useAppDispatch();
  const rowCount = useAppSelector(selectRowCount);
  const pageSize = useAppSelector(selectPageSize);
  const openServer = useAppSelector(selectShouldOpen);

  const unblockUsersFromServer = useAppSelector(selectUnblockUsers);
  const [unblockUsers, setUnblockUsers] = useState(unblockUsersFromServer);

  useEffect(() => {
    dispatch(fetchUnblockUsers(0));
  }, [])

  useEffect(()=>{
    setUnblockUsers(unblockUsersFromServer);
  },[
    unblockUsersFromServer
  ]);

  const rows: GridRowProps[] = unblockUsers.map((request: any) => {
    return {
            id: request.eaterId,
            eaterId: request.eaterId,
            firstName: request.firstName,
            lastName: request.lastName,
            email: request.email,
            status: request.status,
            estatus: request.estatus
          }
  })

  const handleUnblockUser = (id: string) => {
    try {
      dispatch(approveUnblockRequest(id))
      .then(() => {
        dispatch(fetchUnblockUsers(0));
      })
    } catch (e) {
      console.log(e);
    }
  }

  const handleDeclineRequest = (id: string) => {
    try {
      dispatch(declineUnblockRequest(id))
      .then(() => {
        dispatch(fetchUnblockUsers(0));
      })
    } catch (e) {
      console.log(e);
    }
  }
  
  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 250},
    { field: 'estatus', headerName: 'Unblock', width: 150, renderCell: (params): any => {
      return <Button variant="text" onClick={() => handleUnblockUser(params.row.id)}>Approve</Button>
    }},
    { field: 'decline', headerName: 'Decline', width: 150, renderCell: (params): any => {
      return <Button variant="text" onClick={() => handleDeclineRequest(params.row.id)}>Decline</Button>
    }},
  ];

  const getRowID = (row: UnblockUser): string => {
    return row.eaterId;
  }

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  return (
      <PageContainer title='Unblock Users' open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
        <Container>
          <Grid 
            rows={rows} 
            setRows={unblockUsers}
            totalRowCount={rowCount}
            dataColumns={columns}
            paginationServer={true}
            EditToolbar={null}
            empty={null}
            getRowId={getRowID}
            nextPageFn={(newPage: number) => dispatch(fetchUnblockUsers(newPage))}
            rowClickedFn={null}
            deleteFn={(id: string) => {/* do nothing */}}
            editable={false}
            pageSize={pageSize}
            saveFn={(newRow) => {
              /* do nothing */
            }}
            pageSizeOptions={[25,50,100]}
            setPageSize={(pageSize: number) => {dispatch(setPageSize(pageSize))}}
            dashboard={false}
            shouldNavigate={true}
            />
        </Container>
      </PageContainer>
  )
}

export default withAuthenticationRequired(UnblockUsers);
