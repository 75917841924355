/* eslint-disable */

import React, {useState} from 'react'
import PageContainer  from '../../components/PageContainer/PageContainer';
import { saveDish } from './slice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { AddDishContainer, AddDishTitle, AddDishContentHeader, AddDishContentBody } from './Menu.styled';
import { DishRequest } from './types';
import { selectCurrentRestaurant, setOpen, selectShouldOpen, selectMessage, selectSeverity } from '../Restaurant/slice';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from 'react-router-dom';
import { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const AddDish: React.FC = () => {
  const dispatch = useAppDispatch();
  const[ name, setName ] = useState('');
  const[ description, setDescription ] = useState('');
  const[ price, setPrice ] = useState('');
  const [ dietary, setDietary ] = useState('');
  const { alias, menuID } = useParams();
  const restaurant = useAppSelector(selectCurrentRestaurant)

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: restaurant?.name??'', 
      url:`/restaurant/${alias}`
    },
    {
      name: menuID??'', 
      url:`/restaurant/${alias}/menu/${menuID}`
    }
  ]

  const openServer = useAppSelector(selectShouldOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }
    
  return (
    <PageContainer title="Add Dish" breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
    <AddDishContainer>
      <AddDishContentHeader>
        <AddDishTitle>Details</AddDishTitle>
          <Stack direction="row" spacing={2}>
            <Button variant="text" onClick={() => {
              const dishRequest: DishRequest = {
                menuId: menuID??'',
                dishId: '',
                name: name,
                price: Number(price),
                description: description,
              }
              dispatch(saveDish(dishRequest));
              setName('');
              setDescription('');
              setPrice('');
              setDietary('');
            }}>Save</Button>
          </Stack>
      </AddDishContentHeader>
      <AddDishContentBody>
        <Stack direction="row" spacing={5}>
          <TextField id="name" label="Dish Name" variant="outlined" value={name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}/>
          <TextField id="description" label="Description" variant="outlined" value={description} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDescription(event.target.value);
          }}/>
          <TextField id="price" label="Price" variant="outlined" value={price} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPrice(event.target.value);
          }}/>
          <TextField id="dietary" label="Dietary" variant="outlined" value={dietary} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDietary(event.target.value);
          }}/>
        </Stack>
        </AddDishContentBody>
      </AddDishContainer>
    </PageContainer>
  )
}

export default withAuthenticationRequired(AddDish)
