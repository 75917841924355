/* eslint-disable */

import React, {useEffect, useState} from 'react'
import PageContainer  from '../../components/PageContainer/PageContainer';
import { selectNeedsMenus, selectPageSize, fetchNeedsMenus, selectRowCount, deleteMissingMenu, setPageSize, selectCountByStatus } from './slice';
import { addMenu } from '../Menu/slice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { 
        GridColumns, 
      } from '@mui/x-data-grid';
import { Container } from './MissingMenu.styled';
import { GridRowProps } from 'semantic-ui-react';
import { NeedsMenuRequest } from './types';
import Grid from '../../components/Grid/Grid'
import { EditToolbar } from '../../components/EditToolbar/EditToolbar';
import {
  randomId,
} from '@mui/x-data-grid-generator';

import DashboardSummary, {Item} from '../../components/DashboardSummary/DashboardSummary';
import { Summary } from '../Dashboard/Dashboard.styled';
import { withAuthenticationRequired } from '@auth0/auth0-react';


const MissingMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const needsMenusServer = useAppSelector(selectNeedsMenus);
  const [needsMenu, setNeedsMenu] = useState(needsMenusServer);
  const rowCount = useAppSelector(selectRowCount);
  const pageSize = useAppSelector(selectPageSize);
  const countByStatus = useAppSelector(selectCountByStatus);

  useEffect(() => {
    dispatch(fetchNeedsMenus(0));
  }, [])

  useEffect(()=>{
    setNeedsMenu(needsMenusServer);
  },[needsMenusServer]);

  const rows: GridRowProps[] = needsMenu.map(needMenu => {
    return {
            id: needMenu.menuID,
            menuID: needMenu.menuID,
            restaurantID: needMenu.restaurantDTO.restaurantID,
            name: needMenu.restaurantDTO.name,
            date: needMenu.restaurantDTO.created,
            menu: "",
            menuId: needMenu.menuID,
            location: needMenu.restaurantDTO.city + ", " + needMenu.restaurantDTO.state,
            status: needMenu.status,
            exception: needMenu.exception,
            restaurantDTO: needMenu.restaurantDTO
          }
  })

  const getRowID = (row: any) => {
    return row.id;
  }

  const id = randomId();
  const empty = {
    id,
    menuID: id,
    restaurantID: '',
    name: '',
    date: '',
    menu: "",
    menuId: '',
    location: '',
    status: '',
    exception: ''
  }
  
  const columns: GridColumns = [
    { field: 'name', headerName: 'Restaurant Name', width: 250 },
    { field: 'date', headerName: 'Created Date', width: 150 },
    { field: 'menu', headerName: 'Menu', width: 250, editable: true },
    { field: 'location', headerName: 'Location', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'exception', headerName: 'Exception', width: 150 },
  ];

  const statuses: Item[] = countByStatus?.map((city) => {
    const item: Item = {
                        name: city.name,
                        value: ''+city.count
                      }
      return item;
    })??[]

    const [open, setOpen] = useState(false);
    
  return (
      <PageContainer title='Missing Menus' open={open} setOpen={setOpen}>
        <Container>
        <Summary>
          <DashboardSummary items={statuses}/>
        </Summary>
          <Grid 
            rows={rows} 
            totalRowCount={rowCount}
            dataColumns={columns} 
            editable={true}
            saveFn={(newRow) => {
              const nmr: NeedsMenuRequest = {
                menuId: newRow.menuID,
                status: 'COMPLETED',
                url: newRow.menu
              }
              dispatch(addMenu(nmr));
            }}
            paginationServer={true}
            nextPageFn={(newPage: number) => {dispatch(fetchNeedsMenus(newPage))}}
            rowClickedFn={()=> {/*do nothing}*/}}
            deleteFn={(id: string) => dispatch(deleteMissingMenu(id))}
            setRows={setNeedsMenu}
            EditToolbar={EditToolbar}
            empty={empty}
            getRowId={getRowID}
            pageSize={pageSize}
            pageSizeOptions={[25,50,100]}
            setPageSize={(pageSize: number) => {dispatch(setPageSize(pageSize))}}
            />
        </Container>
      </PageContainer>
  )
}

export default withAuthenticationRequired(MissingMenu)
