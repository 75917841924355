import React from 'react'
import {
    Container,
    ContentContainer,
    Heading,
    ItemContainer,
    ItemContent,
    ItemContentName,
    ItemContentValue
} from './DashboardSummary.styled'

export interface Item {
  name: string
  value: string
}
// eslint-disable-next-line
type Props = {
    heading?: string
    items: Item[]
  };

const DashboardSummary: React.FC<Props> = ({
    heading,
    items
}: Props): JSX.Element => {

  return (
    <Container>
      <ContentContainer>
        <Heading>{heading}</Heading>
        <ItemContainer>
          {items.map(item => {
            return (
              <ItemContent key={item.name}>
                <ItemContentName>{item.name}</ItemContentName>
                <ItemContentValue>{item.value}</ItemContentValue>
              </ItemContent>
            );
          })}
        </ItemContainer>
      </ContentContainer>
    </Container>
  );
};

export default DashboardSummary;
