/* eslint-disable */

import React from 'react'
import { Container, ContentHeader, Title, PressReviews, Sentiment, SentimentScore } from './Reviews.styled';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectCurrentRestaurant, selectShouldOpen, selectMessage, selectSeverity, setOpen } from '../Restaurant/slice';
import PageContainer, { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { withAuthenticationRequired } from '@auth0/auth0-react';

const Reviews: React.FC = () => {
  const { alias } = useParams();
  const restaurant = useAppSelector(selectCurrentRestaurant);
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: restaurant?.name??'', 
      url:`/restaurant/${alias}`
    }
  ]

  const openServer = useAppSelector(selectShouldOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const navigateToPressDetail = (pressId: string): void => {
    navigate(`/restaurant/${alias}/press/${pressId}`);
  }

  const navigateToReviewDetail = (reviewId: string): void => {
    navigate(`/restaurant/${alias}/reviews/${reviewId}`);
  }

    return (
      <PageContainer title="Reviews & Press" breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
          <Container>
            <ContentHeader>
              <Title>Reviews & Press</Title>
              <Stack direction="row" spacing={2}>
                <Button variant='text' onClick={() => {
                    navigate(`/restaurant/${alias}/reviews/add-press`)
                }}>Add Press</Button>
              </Stack>
            </ContentHeader>
            <PressReviews>
              <Stack direction="row" spacing={2} sx={{flexWrap: "wrap"}}>
                {restaurant?.press?.map(press => {
                  return (
                    <Card key={press.id} sx={{ width: 300}}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {press.entity}
                        </Typography>
                        <Typography variant="body2">
                          <Sentiment>
                            PRESS
                          </Sentiment>
                        </Typography>
                        <Typography variant="body2">
                          <Sentiment>
                            {press.sentimentLabel}
                          </Sentiment>
                        </Typography>
                        <Typography variant="body2">
                          <SentimentScore>
                            {press.sentimentScore}
                          </SentimentScore>
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small" onClick={() => {
                          navigateToPressDetail(press.id);
                        }}>View</Button>
                      </CardActions>
                    </Card>
                  )
                })}
                {restaurant?.reviews?.map(review => {
                  return (
                    <Card key={review.reviewId} sx={{ width: 300}}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {review.reviewId}
                        </Typography>
                        <Typography variant="body2">
                          <Sentiment>
                            REVIEW - {review.source}
                          </Sentiment>
                        </Typography>
                        <Typography variant="body2">
                          <Sentiment>
                            {review.sentimentLabel}
                          </Sentiment>
                        </Typography>
                        <Typography variant="body2">
                          <SentimentScore>
                            {review.sentimentScore}
                          </SentimentScore>
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small" onClick={() => {
                          navigateToReviewDetail(review.reviewId);
                        }}>View</Button>
                      </CardActions>
                    </Card>
                  )
                })}
              </Stack>
            </PressReviews>
        </Container>
      </PageContainer>
    )
}

export default withAuthenticationRequired(Reviews)
