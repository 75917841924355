import styled from 'styled-components'

export const Container = styled.div`
  padding: 17% 0;
  margin: auto;
  width: 50%;
`

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`

export const Logo = styled.img.attrs(props => ({
  src: "logo.svg"
}))`
  width: 10rem;
  height: 10rem;
`;