import { createAsyncThunk, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import { okaoAxios } from '../../helpers/axios';
import { RootState, store } from '../../redux/store';
import { Restaurant, Summary, AdminRestaurant } from './types'
import { addNewMenu, addMenu, updateMenu, saveDish, deleteDish, publishMenu} from '../Menu/slice';
import { addPress } from '../Reviews/slice';
import { AlertColor } from '@mui/material/Alert';


export interface IRestaurantSlice {
  restaurants?: Restaurant[] | undefined;
  allRestaurantsWithMenus?:Restaurant[]|undefined;
  adminrestaurants?: AdminRestaurant[] | undefined;
  currentRestaurant?: Restaurant | undefined;
  newRestaurantCount: number
  status: string
  count: number
  needsWebsiteCount: number
  needsTagsAndDescriptions: number
  countByStatus: Summary[] | undefined
  topCities: Summary[] | undefined
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined
  currentPage: number
}

const initialState: IRestaurantSlice = {
  restaurants: undefined,
  newRestaurantCount: 0,
  status: 'idle',
  count: 0,
  needsWebsiteCount: 0,
  needsTagsAndDescriptions: 0,
  countByStatus: undefined,
  topCities: undefined,
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  currentPage: 0
};

export const fetchRestaurants = createAsyncThunk('api/fetchRestaurants', async (page: number) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants/${page}?size=${store.getState().dashboard.pageSize}`)
  return response;
})

export const fetchRestaurantsAdminDashboard = createAsyncThunk('api/fetchRestaurantAdminDashboards', async (page: number) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants/admin/${page}?size=${store.getState().dashboard.pageSize}`)
  return response;
})

export const fetchRestaurantByAlias = createAsyncThunk('api/fetchRestaurantByAlias', async (alias: string) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants/${alias}/details`)
  return response;
})

export const fetchRestaurantsWithMenus = createAsyncThunk('api/fetchRestaurantsWithMenus', async () => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants/all-with-menus`)
  return response;
})

export const generateTagsAndDescriptions = createAsyncThunk('api/generateTagsAndDescriptions', async () => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/ingestion/tags-descriptions`));
  return response;
})

export const generateRestaurantImages = createAsyncThunk('api/generateRestaurantImages', async () => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/ingestion/dalle-restaurant-images`));
  return response;
})

export const generateDishImages = createAsyncThunk('api/generateDishImages', async () => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/ingestion/dalle-dish-images`));
  return response;
})

export const publishAllRestaurants = createAsyncThunk('api/publishAllRestaurants', async () => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants/publish-all`));
  return response;
})

export const loadReviews = createAsyncThunk('api/loadAllReviews', async () => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants/load-all-reviews`));
  return response;
})

export const saveRestaurant = createAsyncThunk('api/saveRestaurant', async (nmr: Restaurant) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants`, nmr));
  return response;
})

export const loadReview = createAsyncThunk('api/loadReview', async (restaurantId: string) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants/loadreviews/${restaurantId}`));
  return response;
})

export const publishRestaurant = createAsyncThunk('api/publishRestaurant', async (restaurantID: string) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants/publish/${restaurantID}`));
  return response;
})

export const deleteRestaurant = createAsyncThunk('api/deleteRestaurant', async (restaurantId: string) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await (await okaoAxios(auth).delete(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/restaurants/${restaurantId}`));
  return response;
})

export const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    setRestaurants: (state, action: PayloadAction<Restaurant[] | undefined>) => {
        state.restaurants = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchRestaurants.fulfilled, (state, action) => {
      state.restaurants = action.payload.data.result
      state.count = action.payload.data.count
      state.newRestaurantCount = action.payload.data.newRestaurantCount
      state.needsWebsiteCount = action.payload.data.needsWebsiteCount
      state.needsTagsAndDescriptions = action.payload.data.needsTagsAndDescriptionsCount
      state.countByStatus = action.payload.data.countByStatus
      state.topCities = action.payload.data.topCities
      state.status = 'idle'
    }).addCase(fetchRestaurantsAdminDashboard.fulfilled, (state, action) => {
      state.adminrestaurants = action.payload.data.result
      state.count = action.payload.data.count
      state.newRestaurantCount = action.payload.data.newRestaurantCount
      state.needsWebsiteCount = action.payload.data.needsWebsiteCount
      state.needsTagsAndDescriptions = action.payload.data.needsTagsAndDescriptionsCount
      state.countByStatus = action.payload.data.countByStatus
      state.topCities = action.payload.data.topCities
      state.status = 'idle'
    }).addCase(fetchRestaurantsWithMenus.fulfilled, (state, action) => {
      state.allRestaurantsWithMenus = action.payload.data as Restaurant[]
      state.status = 'idle'
    })
    .addCase(saveRestaurant.fulfilled, (state, action) => {
      const restaurant: Restaurant = action.payload.data as Restaurant
      state.currentRestaurant = restaurant
      state.shouldOpen = true;
      state.message = 'Successfully Saved Restaurant'
      state.status = 'idle'
      state.severity = 'success'
    }).addCase(fetchRestaurantByAlias.fulfilled, (state, action) => {
      const restaurant: Restaurant = action.payload.data as Restaurant
      state.currentRestaurant = restaurant;
      state.status = 'idle'
    }).addCase(addNewMenu.fulfilled, (state, action) => {
      const restaurant: Restaurant = action.payload.data as Restaurant
      state.currentRestaurant = restaurant
      state.shouldOpen = true;
      state.message = 'Successfully Added Menu'
      state.status = 'idle'
      state.severity = 'success'
    }).addCase(loadReview.fulfilled, (state, action) => {
      const restaurant: Restaurant = action.payload.data as Restaurant
      state.currentRestaurant = restaurant
      state.shouldOpen = true;
      state.message = 'Successfully Loaded Reviews'
      state.status = 'idle'
      state.severity = 'success'
    }).addCase(saveDish.fulfilled, (state, action) => {
      const restaurant: Restaurant = action.payload.data as Restaurant
      state.currentRestaurant = restaurant
      state.shouldOpen = true;
      state.message = 'Successfully Saved Dish'
      state.status = 'idle'
      state.severity = 'success'
    }).addCase(updateMenu.fulfilled, (state, action) => {
      const restaurant: Restaurant = action.payload.data as Restaurant
      state.currentRestaurant = restaurant
      state.shouldOpen = true;
      state.message = 'Successfully Updated'
      state.status = 'idle'
      state.severity = 'success'
    })
    .addCase(addPress.fulfilled, (state, action) => {
      const restaurant: Restaurant = action.payload.data as Restaurant
      state.currentRestaurant = restaurant
      state.message = 'Successfully Added Press'
      state.status = 'idle'
      state.severity = 'success'
    })
    .addMatcher(isAnyOf(fetchRestaurantsAdminDashboard.rejected, fetchRestaurantByAlias.rejected,loadReview.rejected, addPress.rejected, deleteDish.rejected, publishMenu.rejected, updateMenu.rejected, saveRestaurant.rejected, publishAllRestaurants.rejected, loadReviews.rejected, saveDish.rejected, addMenu.rejected, publishRestaurant.rejected, deleteRestaurant.rejected, fetchRestaurants.rejected, generateTagsAndDescriptions.rejected, generateDishImages.rejected, generateRestaurantImages.rejected), (state, action) => {
      state.shouldOpen = true;
      state.message = 'Error occurred while performing a restaurant action'
      state.status = 'error'
      state.severity = 'error'
      console.log(action.error);
    }).addMatcher(isAnyOf(fetchRestaurantsAdminDashboard.pending, fetchRestaurantByAlias.pending, loadReview.pending, addPress.pending, addNewMenu.pending, deleteDish.pending, publishMenu.pending, updateMenu.pending, fetchRestaurants.pending, publishAllRestaurants.pending, loadReviews.pending, saveRestaurant.pending, saveDish.pending, addMenu.pending, publishRestaurant.pending, deleteRestaurant.pending, generateTagsAndDescriptions.pending, generateDishImages.pending, generateRestaurantImages.pending), (state, action) => {
      state.status = 'loading'
    }).addMatcher(isAnyOf(deleteDish.fulfilled, publishMenu.fulfilled, addMenu.fulfilled, publishAllRestaurants.fulfilled, loadReviews.fulfilled, publishRestaurant.fulfilled, deleteRestaurant.fulfilled, generateTagsAndDescriptions.fulfilled, generateDishImages.fulfilled, generateRestaurantImages.fulfilled), (state, action) => {
      state.status = 'idle'
      state.shouldOpen = true
      state.severity = 'success'
      state.message = "Success"
    })
  }
});

export const selectRestaurants = (state: RootState): Restaurant[] => state.restaurant.restaurants ?? []
export const selectAdminRestaurants = (state: RootState): AdminRestaurant[] => state.restaurant.adminrestaurants ?? []
export const selectRestaurantsWithMenus = (state: RootState): Restaurant[] => state.restaurant.allRestaurantsWithMenus ?? []
export const selectCurrentRestaurant = (state: RootState): Restaurant | undefined => state.restaurant.currentRestaurant ?? undefined
export const selectRestaurantWithMenuByAlias = (alias: string) => (state: RootState): Restaurant | undefined => state.restaurant.allRestaurantsWithMenus?.find(restaurant => restaurant.alias === alias) ?? undefined
export const selectRowCount = (state: RootState): number => state.restaurant.count
export const selectNewRestaurantCount = (state: RootState): number => state.restaurant.newRestaurantCount
export const selectIsLoading = (state: RootState): boolean => state.restaurant.status === "loading"
export const selectNeedsWebsiteCount = (state: RootState): number => state.restaurant.needsWebsiteCount
export const selectNeedsTagsAndDescriptions = (state: RootState): number => state.restaurant.needsTagsAndDescriptions
export const selectCountByStatus = (state: RootState): Summary[] | undefined => state.restaurant.countByStatus
export const selectTopCities = (state: RootState): Summary[] | undefined => state.restaurant.topCities
export const selectShouldOpen = (state: RootState): boolean => state.restaurant.shouldOpen
export const selectMessage = (state: RootState): string | undefined => state.restaurant.message
export const selectSeverity = (state: RootState): AlertColor | undefined => state.restaurant.severity
export const selectCurrentPage = (state: RootState): number => state.restaurant.currentPage

export const { setRestaurants, setOpen, setCurrentPage } = restaurantSlice.actions;
export default restaurantSlice.reducer
